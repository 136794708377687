export class ProductDetails {
    serialNumber: string;
    productLineId: string;
    assemblyPartNo: string;
    cliTagNo: string;
    qrCodeId: string;
    docLocation: string;
    instVideo:string;
    maintVideo:string;
    contName1: string;
    customerAccountNo:any;
    contEmail1: string;
    contPhone1: string;
    contName2: string;
    contEmail2: string;
    contPhone2:string;
    updatedOn:string;
    prodAttributes:prodAttributes[];
    prodFiles:prodFiles[];
    updatedBy:any
    globalPDFs:any[];
    userAccessIndicator:any;
}

export class prodAttributes{
    id:number;
    attributeId:attributeId;
    attributeValue:string
}

export class attributeId{
    id: number;
    prodLine: string;
    attributeName: string;
}
export class prodFiles{
    docType:string;
    docLocation:string;
    id:number;
}

export interface IspareParts{
    currency: string
    description: string
    id: number
    partNo: number
    price: number
    productLineId: string
    productSerialNum: string
    requestCycle: number
    checked: boolean
    discountPrice: number
}

export interface IsendOthers{
    emailIds: string[],
    message: string,
    listSpareParts: IspareParts[];
}