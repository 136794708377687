import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationExtras } from '@angular/router';
import { ProductService } from '../services/product.service';
import { IspareParts, ProductDetails } from 'src/app/models/productDetails';
import { DOCUMENT, Location } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataSharingService } from '../services/data-sharing.service';
declare var require: any
declare var angular: any;
import { environment } from '../../environments/environment'
import { FetchUserDetailsService } from '../services/fetch-user-details.service';
import jwt_decode from "jwt-decode";

export class ManualDoc {
  doclocation: string;
  fileName: string;
}

@Component({
  selector: 'app-instructional-manual',
  templateUrl: './instructional-manual.component.html',
  styleUrls: ['./instructional-manual.component.css']
})

export class InstructionalManualComponent implements OnInit {
  [x: string]: any;
  panelOpenState = false;
  public productId: string;
  productDetails: any;//ProductDetails[];
  productDetailObject = new ProductDetails();
  newProducts: any;
  prodFiles: any;
  notes: any;
  notesLength: number;
  notesFileCount: number;
  loader: boolean = true;
  angular: any;
  prodDoc: ManualDoc[] = [];
  dataSheetDoc: ManualDoc[] = [];
  dataBookDoc: ManualDoc[] = [];
  spareDoc: ManualDoc[] = [];
  weldingSheetDoc: ManualDoc[] = [];
  othersDoc: ManualDoc[] = [];
  globalDoc: String[] = [];
  globalDocument: ManualDoc[] = [];
  isMessage: boolean = false;
  isSpares: boolean = false;
  instVideo: any;
  maintVideo: any;
  docLocation: any;
  lastSlashValue: any;
  dataSheet: any;
  dataBook: any;
  weldingSheet: any;
  otherSheet: any;
  dataSheetSlashValue: any;
  dataBookSlashValue: any;
  weldingSlashValue: any;
  otherSlashValue: any;
  docName: any;
  docSpareLocation: any;
  spareLastSlashValue: any;
  customerAccountNo: any;
  docSpareName: any;
  productFirstAttributes: string;
  unrestrictedUser: boolean = true;
  validateEmail1: boolean = false;
  validateEmail2: boolean = false;
  validateURL1: boolean = false;
  validateURL2: boolean = false;
  globalDocFileName: any;
  globalProductLineFileName: any;
  isGlobalPDFs: boolean = false;
  userAccessIndicator:any;
  productLineId:any;
  accessMessage:boolean;
  sparePartsResult: any;
  isgetSpareParts: boolean = false;
  sparePartsData: IspareParts[] = [];
  isselectAll: boolean = false;
  isCheckQuoteOrder: any;
  isOrgPrice: boolean = false;
  addNotes = {
    productNotes: {
      productSerialNo: null,
      productNotes: null,
      imageLoc: null,
      updatedBy: { operatorId: 0 },
      updatedOn: null,
      isDeleted: null,
      deletedBy: null,
      quoteText: null
    },
    productNotesFile: [{
      filepath: null,
      fileType: null,
    }]
  };
  constructor(private route: Router, private routes: ActivatedRoute,private snackBar: MatSnackBar, private productService: ProductService, private _location: Location, private dataSharingService: DataSharingService,  private fetchCount:FetchUserDetailsService
    ) {
    this.routes.queryParams.subscribe((params) => {
      if (params["productId"] != null) {
        this.productId = params["productId"];
        sessionStorage.setItem('productId', this.productId);
      }
    });
    this._location.replaceState(this._location.path().split("?")[0], "");
    this.dataSharingService.setHeader(false);
    this.dataSharingService.unrestrictedUserAccess.subscribe(data => this.unrestrictedUser = data);
    console.log("unrestrictedUser", this.unrestrictedUser);
    if (localStorage.getItem("accessToken")) {
      this.dataSharingService.setUnrestrictedUser(false);
      this.unrestrictedUser = false;
    }
    let decodedToken = jwt_decode(localStorage.getItem("accessToken"));
    this.email = decodedToken['unique_name'].includes('#') ? decodedToken['unique_name'].split("#").pop() : decodedToken['unique_name'];
    this.fetchCount.fetchUseryEmail(this.email).subscribe(data => {
    this.dataSharingService.setUserTypeId(data?.userTypeId?.userTypeId);
    console.log("uTypeid", this.dataSharingService.getUserTypeId());
    });
    this.dataSharingService.fetchUserRole.subscribe((res: string) => {
      this.role = res;
    })
  }

  ngOnInit(): void {
    this.productId = this.productId ?? sessionStorage.getItem('productId');
    this.fetchPrductDetails();
    window.addEventListener("pageshow", function (event) {
      var historyTraversal = event.persisted ||
        (typeof window.performance != "undefined" &&
          window.performance.navigation.type === 2);
      if (historyTraversal) {
        // Handle page restore.
        window.location.reload();
      }
    });
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('productId');
  }

  fetchPrductDetails() {
    if (this.productId) {
      this.productService.fetchProductDetails(this.productId).subscribe(data => {
        console.log("in fetchMethod", this.productId);
        console.log("data", data);
        this.loader = false;
        this.productDetails = data;
        this.newProducts = [];
        this.newProducts = this.productDetails.filter((data) => data.serialNumber == this.productId);
        console.log("newProduct", this.newProducts);
        this.productDetailObject = this.newProducts[0];
        this.docLocation = this.productDetailObject.docLocation;
        this.productFirstAttributes = this.productDetailObject.prodAttributes[0].attributeValue;
        if (this.productDetailObject.contEmail1?.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)) {
          this.validateEmail1 = true;
        }

        if (this.productDetailObject.contEmail1?.match(/^(https:|http:|www\.)\S*/)) {
          this.validateURL1 = true;
        }
        if (this.productDetailObject.contEmail2?.match(/^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/)) {
          this.validateEmail2 = true;
        }
        if (this.productDetailObject.contEmail2?.match(/^(https:|http:|www\.)\S*/)) {
          this.validateURL2 = true;
        }
        console.log("productObject", this.productDetailObject);
        this.instVideo = this.productDetailObject.instVideo;
        this.maintVideo = this.productDetailObject.maintVideo;
        this.prodFiles = this.productDetailObject.prodFiles;
        this.userAccessIndicator = this.productDetailObject.userAccessIndicator;
        this.productLineId = this.productDetailObject.productLineId;
        this.customerAccountNo = this.productDetailObject.customerAccountNo;
        this.checkQuoteAndOrderForProd();
        console.log(this.instVideo);
        console.log(this.maintVideo);
        this.prodDoc = [];
        this.spareDoc = [];
        this.dataSheetDoc = [];
        this.globalDoc = this.productDetailObject.globalPDFs;


        for (var pfile of this.prodFiles) {
          if (pfile.docType == "IOM") {
            let documnt = new ManualDoc();
            documnt.doclocation = pfile.docLocation;
            var res = pfile.docLocation.split('/');
            this.lastSlashValue = res[res.length - 1];
            documnt.fileName = this.lastSlashValue.replaceAll('%20', ' ');
            // this.prodDoc = pfile.docLocation;
            this.prodDoc.push(documnt);
            //console.log("prodLocation:",this.prodDoc);
          }
        }


        if (Object.keys(this.prodDoc).length > 0) {
          this.isMessage = true;
        }

        /* for(var doc of this.prodDoc){
          this.docLocation = doc;
          var res = this.docLocation.split('/');
         }
         if(res!=undefined){
         this.lastSlashValue = res[res.length-1];
         this.docName = this.lastSlashValue.replaceAll('%20', ' ');
         console.log("docName", this.docName);
        }*/


        for (var dataSheetFile of this.prodFiles) {
          if (dataSheetFile.docType == "Data Sheet") {
            let documnt = new ManualDoc();
            documnt.doclocation = dataSheetFile.docLocation;
            var res = dataSheetFile.docLocation.split('/');
            this.dataSheetSlashValue = res[res.length - 1];
            documnt.fileName = this.dataSheetSlashValue.replaceAll('%20', ' ');
            // this.prodDoc = pfile.docLocation;
            this.dataSheetDoc.push(documnt);
          }
        }

        if (Object.keys(this.dataSheetDoc).length > 0) {
          this.dataSheet = true;
        }

        for (var dataBookFile of this.prodFiles) {
          if (dataBookFile.docType == "Data Books") {
            let document = new ManualDoc();
            document.doclocation = dataBookFile.docLocation;
            var res = dataBookFile.docLocation.split('/');
            this.dataBookSlashValue = res[res.length - 1];
            document.fileName = this.dataBookSlashValue.replaceAll('%20', '');
            this.dataBookDoc.push(document);
          }
        }

        if (Object.keys(this.dataBookDoc).length > 0) {
          this.dataBook = true;
        }

        for (var spareFile of this.prodFiles) {
          if (spareFile.docType == "Spare Parts") {
            let documnt = new ManualDoc();
            documnt.doclocation = spareFile.docLocation;
            var res = spareFile.docLocation.split('/');
            this.spareLastSlashValue = res[res.length - 1];
            documnt.fileName = this.spareLastSlashValue.replaceAll('%20', ' ');
            // this.prodDoc = pfile.docLocation;
            this.spareDoc.push(documnt);
          }
        }

        if (Object.keys(this.spareDoc).length > 0) {
          this.isSpares = true;
        }
        for (var weldingFile of this.prodFiles) {
          if (weldingFile.docType == "Welding") {
            let document = new ManualDoc();
            document.doclocation = weldingFile.docLocation;
            var res = weldingFile.docLocation.split('/');
            this.weldingSlashValue = res[res.length - 1];
            document.fileName = this.weldingSlashValue.replaceAll('%20', ' ');
            this.weldingSheetDoc.push(document);
          }
        }

        if (Object.keys(this.weldingSheetDoc).length > 0) {
          this.weldingSheet = true;
        }

        for (var othersFile of this.prodFiles) {
          if (othersFile.docType == null) {
            let document = new ManualDoc();
            document.doclocation = othersFile.docLocation;
            var res = othersFile.docLocation.split('/');
            this.otherSlashValue = res[res.length - 1];
            document.fileName = this.otherSlashValue.replaceAll('%20', ' ');
            this.othersDoc.push(document);
          }
        }

        if (Object.keys(this.othersDoc).length > 0) {
          this.otherSheet = true;
        }

        for (var gFile of this.globalDoc) {
          console.log("inside global doc for loop");
          let dir = gFile;
          let re = /%20/gi;
          this.globalDocFileName = dir.replace(re, ' ');
          this.globalProductLineFileName = this.productDetailObject.productLineId + '/' + this.globalDocFileName;
          let documnt = new ManualDoc();
          documnt.doclocation = this.globalProductLineFileName;
          documnt.fileName = this.globalDocFileName;
          this.globalDocument.push(documnt);
        }
        if (Object.keys(this.globalDocument).length > 0) {
          this.isGlobalPDFs = true;
        }

      }), error => {
        console.log(error);
      }
      this.dataSharingService.fetchNotes.subscribe((data) => {
        if (data == true) {
          this.fetchProductNotes();
        }
      });
      this.fetchProductNotes();
      this.fetchSpareParts(this.productId);
    }
  }

   fetchSpareParts(productId: any) {
    this.productService.fetchSpareParts(productId).subscribe((res) => {
      console.log("sparts", res);
      this.sparePartsResult = res;
      if (res?.some(r => r.price > 0)) {
        this.isOrgPrice = true;
      }
      if (res.length > 0 ) {
        this.isgetSpareParts = true;
      }
    });
  }

  fetchProductNotes() {
    this.productService
    .fetchProductNotes(this.productId)
    .subscribe((notes) => {
      this.notes = notes;
      this.convertNotes();
      console.log("notes:", this.notes);
      this.notesLength = Object.keys(this.notes).length;
      console.log("notes length:", Object.keys(this.notes).length);
      // console.log("productNotesFile length:",Object.keys(this.notes.productNotesFile).length);
      for (var notesFile of this.notes) {
        this.notesFileCount = Object.keys(
          notesFile.productNotesFile
        ).length;
      }
    }),
    (error) => {
      console.log(error);
    };
  }
  isUserType() {
    console.log("uType", this.dataSharingService.getUserTypeId());
    return this.dataSharingService.getUserTypeId();
  }

  checkQuoteAndOrderForProd() {
    this.productService.checkQuoteandOrder(this.productLineId).subscribe((res) => {
        this.isCheckQuoteOrder = res;
        console.log("quoteorder", this.isCheckQuoteOrder);
      });
}

CheckBoxVisibleForQuoteOrder() {
  return (this.isCheckQuoteOrder && (this.isCheckQuoteOrder.quoteAdmin || this.isCheckQuoteOrder.orderAdmin));
}

selectAll(isSelectAll) {
  console.log("spresult", this.sparePartsResult, isSelectAll);
  this.sparePartsResult = this.sparePartsResult.map((sp: any) => {
    sp.checked = isSelectAll;
    return sp;
  });
}
checkForAllSelect() {
  let selectedSP = this.sparePartsResult.filter((sp: any) => {
    return sp.checked === true;
  });
  if (selectedSP.length === this.sparePartsResult.length) {
    this.isselectAll = true;
  } else if (selectedSP.length > 0) {
    this.isselectAll = false;
  }
}
resetSelection() {
  this.sparePartsResult = this.sparePartsResult.map((sp: any) => {
    sp.checked = false;
    return sp;
  });
  this.isselectAll = false;
}

convertNotes() {
  let quoteNo = 0;
  let orderNo = 0;
  for (let i = 0; i < this.notes.length; i++){
    let note = this.notes[i];
    let noteText = note.productNotes.productNotes;
    if (noteText.startsWith("Quote: quoteNo")) {
      noteText = noteText.replace("quoteNo", (quoteNo + 1) + "</span>");
      noteText = "<div class='table_notes'><span class='fb'>" + noteText + "</div>";
      quoteNo = quoteNo + 1;
      this.notes[i].productNotes.productNotes = noteText;
    } else if (noteText.startsWith("Order: orderNo")) {
      noteText = noteText.replace("orderNo", (orderNo + 1) + "</span>");
      noteText = "<div class='table_notes'><span class='fb'>" + noteText + "</div>";
      orderNo = orderNo + 1;
      this.notes[i].productNotes.productNotes = noteText;
    }
  }
}

sparePartsQuotesAndOrder(quoteOrOrder: string) {
  this.dataSharingService.setLoader(true);
  let postData = this.sparePartsResult.filter((sp: any) => sp.checked);
  console.log(postData);
  let notesText = "";
  if (quoteOrOrder === "quote") {
    notesText = "Quote: quoteNo";
  } else if (quoteOrOrder === "order") {
    notesText = "Order: orderNo";
  }
  for (let i = 0; i < postData.length; i++){
    notesText =  notesText + "<div class='table_notes_item'><p> <strong>Description:</strong> " + postData[i].description + "</p> <p><strong>Part No:</strong> " + postData[i].partNo + "</p></div>"; 
    console.log(notesText);
  }
  
  if (postData.length > 0 && quoteOrOrder === "quote") {
    this.productService.sendSPQuotes(postData).subscribe(
      (res) => {
        console.log(res, "resss");
        this.resetSelection();
        if (res.Message === "Success") {
          this.dataSharingService.setLoader(false);
          this.snackBar.open(res.Result, "", {
            duration: 2500,
          });
          this.addNotes.productNotes.productSerialNo = postData[0].productSerialNum;
          this.addNotes.productNotes.productNotes = notesText;
          console.log('this.addNotes.productNotes.productNotes', this.addNotes.productNotes.productNotes);
          this.addNotes.productNotes.updatedBy.operatorId = 2;
          console.log('myDate:', this.myDate);
          this.addNotes.productNotes.updatedOn = new Date();
          this.addNotes.productNotes.imageLoc = '';
          this.addNotes.productNotes.isDeleted = 0;
          this.addNotes.productNotes.deletedBy = null;
          this.addNotes.productNotesFile = [];
          
          this.productService.addProductNotes(this.addNotes).subscribe(data => {
            console.log("NoteData:", data)
            this.fetchProductNotes();
          });
        } else {
          this.dataSharingService.setLoader(false);
          this.snackBar.open("Quote Email not sent", "", {
            duration: 2500,
          });

        }
      },
      (err) => {
        this.snackBar.open("Quote Email not sent", "", {
          duration: 2500,
        });
      }
    );
  } else if (postData.length > 0 && quoteOrOrder === "order")  {
    this.productService.sendSPOrder(postData).subscribe(
      (res) => {
        console.log(res, "resss");
        this.resetSelection();
        if (res.Message === "Success") {
          this.dataSharingService.setLoader(false);
          this.snackBar.open(res.Result, "", {
            duration: 2500,
          });
          this.addNotes.productNotes.productSerialNo = postData[0].productSerialNum;
          this.addNotes.productNotes.productNotes = notesText;
          console.log('this.addNotes.productNotes.productNotes', this.addNotes.productNotes.productNotes);
          this.addNotes.productNotes.updatedBy.operatorId = 2;
          console.log('myDate:', this.myDate);
          this.addNotes.productNotes.updatedOn = new Date();
          this.addNotes.productNotes.imageLoc = '';
          this.addNotes.productNotes.isDeleted = 0;
          this.addNotes.productNotes.deletedBy = null;
          this.addNotes.productNotesFile = [];
          
          this.productService.addProductNotes(this.addNotes).subscribe(data => {
            console.log("NoteData:", data)
            this.fetchProductNotes();
          });
        } else {
          this.dataSharingService.setLoader(false);
          this.snackBar.open("Order Email not sent", "", {
            duration: 2500,
          });
        }
      },
      (err) => {
        this.snackBar.open("Order Email not sent", "", {
          duration: 2500,
        });
      }
    );
  } else {
    this.dataSharingService.setLoader(false);
    this.snackBar.open("Please select at least one checkbox", "", {
      duration: 2500,
    });
  }
}
  
closeFormPopup() {
  this.postFormSubmit = !this.postFormSubmit;
  this.sendUserForm.reset();
  this.resetSelection();
}

sendOthers() {
  let receivedListSparePartsResult = this.sparePartsResult?.filter((sp: any) => sp.checked);
  if (receivedListSparePartsResult.length > 0) {
    this.postFormSubmit = true;
    this.usPopupToggle = true;
    } else {
    this.snackBar.open("Please select at least one checkbox", "", {
      duration: 2500,
    });     
  }
}

sendOthersToSpareParts() {
  let postData: any = {};
  let emailIds: any = [];
  emailIds.push(this.sendUserForm.value.emailIds);
  postData.emailIds = emailIds;

  // let emailIds: any = this.sendUserForm.value.emailIds.split(",");
  // postData.emailIds = emailIds; 
  postData.message = this.sendUserForm.value.message;
  let receivedListSparePartsResult = this.sparePartsResult?.filter((sp: any) => sp.checked);
  postData.listSpareParts = receivedListSparePartsResult;
  console.log("sotospa pd:", postData);
  this.dataSharingService.setShowSmallLoader(true);
  let notesText = "Quote: quoteNo";
  for (let i = 0; i < receivedListSparePartsResult.length; i++){
    notesText =  notesText + "<div class='table_notes_item'><p> <strong>Description:</strong> " + receivedListSparePartsResult[i].description + "</p> <p><strong>Part No:</strong> " + receivedListSparePartsResult[i].partNo + "</p></div>"; 
    console.log(notesText);
  }
  this.productService.sendOthersToSpareParts(postData).subscribe(res => {
    if (res.Message == "Success") {
      this.dataSharingService.setShowSmallLoader(false);
      this.sendUserForm.reset();
      this.postFormSubmit = false;
      this.usPopupToggle = false;
      this.snackBar.open(res.Result, "", {
        duration: 2500,
      });
      this.resetSelection();
      this.addNotes.productNotes.productSerialNo = postData.listSpareParts[0].productSerialNum;
      this.addNotes.productNotes.productNotes = notesText;
      this.addNotes.productNotes.updatedBy.operatorId = 2;
      this.addNotes.productNotes.updatedOn = new Date();
      this.addNotes.productNotes.imageLoc = '';
      this.addNotes.productNotes.isDeleted = 0;
      this.addNotes.productNotes.deletedBy = null;
      this.addNotes.productNotesFile = [];
      
      this.productService.addProductNotes(this.addNotes).subscribe(data => {
        console.log("NoteData:", data)
        this.fetchProductNotes();
      });
      
    }
  });
}

  gotoProductDetails() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        productId: this.productId,
      }
    };
    this.route.navigate(['/product-details'], navigationExtras);
  }

  ShowDashboardPage() {
    this.route.navigate(['/product-search']);
  }

  ShowFAQPage() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        productId: this.productId
      },
    };

    this.route.navigate(['/faq'], navigationExtras);
  }

  ShowProductDetailPage() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        productId: this.productId
      }
    };
    this.route.navigate(['/product-details'], navigationExtras);
  }

  downloadFile(fileName) {
    //const pdfUrl = "traceability/Rapid Opening Closure/UR01343/Installation Operation and Maintenance Manual – IOM.pdf";
    var str = fileName.split(' ').join('^');
    var form = document.createElement("form");
    console.log(str);
    //  form.action = "https://test-traceability-service.azurewebsites.net/downloadFile";
    form.action = `${environment.apiUrl}/downloadFile`;
    angular.element(form).append('<input type="hidden" name="fileName" value=' + str + '>');
    // console.log(form.get("fileName"));
    // form.setAttribute("fileName",pdfUrl);        
    form.method = "post";
    angular.element(document.body).append(form);
    form.submit();
    /* this.faqService.downloadFile(pdfUrl).subscribe(data=>{
        // if(this.success=='Y'){
           this.downloadData(data);
         });*/

  }

  downloadGlobalPDF(docLocation) {
    var str = docLocation.split(' ').join('^');
    var form = document.createElement("form");
    console.log(str);
    form.action = `${environment.apiUrl}/downloadGlobalPDF`;
    angular.element(form).append('<input type="hidden" name="fileName" value=' + str + '>');
    form.method = "post";
    angular.element(document.body).append(form);
    form.submit();
  }

  openLink(link: string) {
    let url: string = '';
    if (!/^http[s]?:\/\//.test(link)) {
      url += 'http://';
    }
    url += link;
    window.open(url, "_blank");
  }

  grantAccessRequest(){
    this.accessMessage = true;
    this.productService.grantAccess(this.productLineId, this.customerAccountNo, this.productId).subscribe(data=>{
      this.accessMessage = true;
      this.snackBar.open('Access request sent to Admin.','', {
        duration: 3000,})
    });
  }

  showGrantAccessButton(){
    if(this.userAccessIndicator == 0 ){
      return false;
    }
    else if(this.userAccessIndicator == 1){
      return true;
    }
  }

}
