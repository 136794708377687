    <div class="breadcrumb-sa"><span class="breadcrumb-link" (click)="ShowDashboardPage()">DASHBOARD</span> > <span class="breadcrumb-text">REGISTER ON USER’S BEHALF</span>
    </div>
  
<div class="registerPage">

          <p class="registerTitle ">REGISTER ON USER’S BEHALF</p>
            <form [formGroup]="registerForm" (ngSubmit)="addUserOnBehalf()" class="registerRow" novalidate>

              <tr>              
                  <td><label for="firstName">FIRST NAME</label></td>
                  <td><label for="lastName" class="nameLable">LAST NAME</label></td>
                  <td><label for="email" class="nameLable" >EMAIL ADDRESS</label></td>
                  <td><label for="mobileNo" class="nameLable">MOBILE NUMBER</label></td>
                  <td><label for="location" class="nameLable">LOCATION</label></td>
                  <td><label for="jobTitle" class="nameLable">JOB TITLE</label></td>
                  <td><label for="department" class="nameLable">DEPARTMENT</label></td>
                  <td><label for="orgName" class="nameLable">COMPANY</label></td>
                  <td><label for="brand" class="nameLable">BRAND</label></td>
              </tr>

              <tr style="vertical-align: top;">
                <td><input type="text" formControlName="firstName" class="form-control textBoxFormat" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                  <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                      <div *ngIf="f.firstName.errors.required">First Name is required</div>
                  </div>
                </td>

                <td><input type="text" formControlName="lastName" class="form-control textBoxFormat" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }"/>
                  <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback" style="margin-left: 10px;">
                    <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                  </div>
                </td>

                <td><input type="email" formControlName="email" class="form-control textBoxFormat" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                  <div *ngIf="submitted && f.email.errors" class="invalid-feedback" style="margin-left: 10px;">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                  </div> 
                  <div *ngIf="(f.email.invalid && f.email.touched) || f.email.dirty" style="margin-left: 10px;">
                    <!-- <small *ngIf="f.email.errors?.required" class="text-danger">Email is required</small> -->
                    <small *ngIf="f.email.errors?.pattern" class="text-danger">Please provide a valid email address</small>
                  </div>
                </td>

                <td><input type="text" formControlName="mobileNo" class="form-control textBoxFormat" [ngClass]="{ 'is-invalid': submitted && f.mobileNo.errors }" />
                  <!--<div *ngIf="submitted && f.mobileNo.errors" class="invalid-feedback">
                      <div *ngIf="f.mobileNo.touched && f.mobileNo.errors.required">Mobile Number is required</div>
                  </div>-->
                  <div *ngIf="(f.mobileNo.invalid && f.mobileNo.touched) || f.mobileNo.dirty" style="margin-left: 10px;">
                    <small *ngIf="f.mobileNo.errors?.required" class="text-danger">Mobile Number is required</small>
                    <small *ngIf="f.mobileNo.errors?.pattern || f.mobileNo.errors?.maxlength || f.mobileNo.errors?.minlength" class="text-danger">Please provide a valid 10 digit mobile Number</small>
                  </div>
                </td>

                <td><input type="text" formControlName="location" class="form-control textBoxFormat"/></td>

                <td><input type="text" formControlName="jobTitle" class="form-control textBoxFormat"/></td>

                <td><input type="text" formControlName="department" class="form-control textBoxFormat"/></td>

                <td><input type="text" formControlName="orgName" class="form-control textBoxFormat" [ngClass]="{ 'is-invalid': submitted && f.orgName.errors }" />
                    <div *ngIf="submitted && f.orgName.errors" class="invalid-feedback" style="margin-left: 10px;">
                      <div *ngIf="f.orgName.errors.required">Company is required</div>
                  </div>
                </td> 
                <td class="reg-select">
                    <!-- <button class="addNewRegistrationBtn" id="addRegisterOnBehalf" type="submit">SAVE REGISTRATION
                    </button> -->
                    <select id="brand" name="brand" formControlName="brand" class="form-control textBoxFormat" style="padding: unset;" [ngClass]="{ 'is-invalid': submitted && f.brand.errors }"  >
                      <option *ngFor="let k of brandArr" [value]="k.value">{{k.value}}</option>
                    </select>
                    <div *ngIf="submitted && f.brand.errors" class="invalid-feedback" style="margin-left: 10px;">
                      <div *ngIf="f.brand.errors.required">Brand is required</div>
                    </div>
                </td>
              </tr>
              <tr style="text-align: center;">
                <td colspan="9">
                  <div class="btn-align">
                    <button class="addNewRegistrationBtn"  id="addRegisterOnBehalf" type="submit" >SAVE REGISTRATION</button> 
                  </div>
                </td>
              </tr>             
            </form>
        <div>              
          <ejs-grid #grid [dataSource]='dynamicUserRows' [allowSorting]='true' [allowSelection]='true' allowFiltering='true' [filterSettings]='filter'
           allowScrolling='true'  [editSettings]='editSettings' (rowSelected)='rowSelected($event)'>
              <e-columns>
                <e-column type='checkbox' [allowFiltering]='false' [allowSorting]='false' width='30'></e-column>
                <e-column field='firstName' headerText='FIRST NAME' width='120'></e-column>
                <e-column field='lastName' headerText='LAST NAME' width='120'></e-column>
                <e-column field='email' headerText='EMAIL' width='170' isPrimaryKey='true' matTooltip="Info about the action"></e-column>
                <e-column field='mobileNo' headerText='MOBILE NUMBER' width='150'></e-column>
                <e-column field='location' headerText='LOCATION' width='120'></e-column>
                <e-column field='jobTitle' headerText='JOB TITLE' width='120'></e-column>
                <e-column field='department' headerText='DEPARTMENT' width='130'></e-column>
                <e-column field='orgName' headerText='COMPANY' width='120'></e-column>
                <e-column field='brand' headerText='BRAND' width='120' editType= 'dropdownedit'  [edit]='brandParam'>
              </e-column>
                <e-column field='approvalStatus' headerText='STATUS' width='120'></e-column>
                
               
                <e-column width=60 [commands]='commands'></e-column>
                <!-- <e-column width='30'>
                  <ng-template #template let-data>
                    <i class='fas fa-pen'></i>&nbsp;&nbsp;&nbsp;
                    <i class='fas fa-trash' (click)="deleteUserOnBehalf($event)"></i>
                  </ng-template>
                  </e-column> -->
            </e-columns>
          </ejs-grid>
          <div class="grid-btn">
            <button class="btn" (click)="ShowDashboardPage()">BACK</button>
            <button class="btn" (click)="editUserOnBehalf(dynamicUserRows)">SAVE</button>
          </div>
          
    </div>

</div> 