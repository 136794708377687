import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FetchUserDetailsService } from '../services/fetch-user-details.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  userdetails:boolean = false;
  public emailValue: string;
  userData: any;
  constructor( private route: Router ) {
  }

  ngOnInit(): void {
  }
  ShowDashboardPage(){
    this.route.navigate(['/product-search']);
    }
}
