import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { HttpClient } from '@angular/common/http';
import { DataSharingService } from '../services/data-sharing.service';
import { FetchUserDetailsService } from 'src/app/services/fetch-user-details.service';
import { OAuthSettings } from '../../oauth';
import jwt_decode from "jwt-decode";
import { GlobalService } from '../app.service';
import { EMPTY } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  submitted = false;
  loading = false;
  loginForm: FormGroup;
  subscription:any;
  outhresponse:any;
  profile:any;
  isHeader:boolean=true;
  message: boolean = false;
  firstName: any;
  lastName: any;
  token: any;
  userTypeId: any;
  emptyErrMessage: boolean = false;
  constructor(private router: Router, private http: HttpClient, private dataSharingService:DataSharingService, private authService: MsalService,
    private broadcastService: BroadcastService,private  fetchCount:FetchUserDetailsService) {
    this.loginForm = new FormGroup({
      userName: new FormControl('', [Validators.required])
    });
  }

  
  ngOnInit(): void {
    console.log("headerValue",this.isHeader);
    this.dataSharingService.setHeader(this.isHeader);
    /*this.broadcastService.subscribe("msal:loginSuccess", payload => {
      // do something here
      
    });*/
    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }
  this.authService.acquireTokenPopup(OAuthSettings).then((tokenResponse) => {
    console.log(tokenResponse.accessToken);
    this.setAccessToken(tokenResponse.accessToken);
    this.dataSharingService.fetchUserRoleFromServer().subscribe();
     this.router.navigate(['/product-search']);
}).catch(function (error) {
    console.log(error);
});
});
   
  }
  setAccessToken(accessToken){
    const now = new Date();
    const item = {
      value: accessToken,
      expiry: now.getTime() + 500000,
    }
    localStorage.setItem("accessToken", JSON.stringify(item));
  }

  onSubmit() {
    this.submitted = true;    
    if (this.loginForm.get('userName').value.trim().length === 0) {
      this.emptyErrMessage = true;
      this.message = false;
    }
    if (this.loginForm.valid) {
      let email = this.loginForm.get("userName").value;
      this.fetchCount.fetchLoginByEmail(email).subscribe(data =>{
        console.log("userData", data);
        this.dataSharingService.refreshAccessToken();
        const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
     
        this.authService.loginRedirect( {
          extraScopesToConsent: ["user.read", "openid", "profile"]
       });
       

      }, (err) => {
      this.emptyErrMessage = false;
      this.message=true;
    })
      
    }
    this.loading = true;
  }

  get f(){
    return this.loginForm.controls;
  }

  goToRegister() {
      this.router.navigate(['register-form']);
    }
  
  // goToRegister(){
  
  // }

//   login() {
//     const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

//     if (isIE) {
//       this.authService.loginRedirect({
//         extraScopesToConsent: ["user.read", "openid", "profile"]
//       });
//     } else {
//       this.authService.loginPopup({
//         extraScopesToConsent: ["user.read", "openid", "profile"]
//       });
//     }
// }

// logout() {
//   this.authService.logout();
// }

// getProfile() {
//   const graphMeEndpoint = "https://graph.microsoft.com/v1.0/me";
//   this.http.get(graphMeEndpoint).toPromise()
//     .then(profile => {
//       this.profile = profile;
//     });
// }



  
 
}
