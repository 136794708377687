import { AfterViewInit, Component, OnInit } from "@angular/core";
import { DataSharingService } from "../services/data-sharing.service";
import { Router, NavigationExtras } from "@angular/router";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MsalService, BroadcastService } from "@azure/msal-angular";
import jwt_decode from "jwt-decode";
import { ProductService } from "../services/product.service";
import { FetchUserDetailsService } from "../services/fetch-user-details.service";

@Component({
  selector: "header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  isHeader: boolean;
  downArrow: boolean = true;
  unrestrictedUser: boolean;
  token: any;
  firstName: any;
  lastName: any;
  accessToken: any;
  email: any;
  role: string;
  name: string;
  uniqueName: string;
  delAcct: boolean = false;

  constructor(
    private dataSharingService: DataSharingService,
    private route: Router,
    public dialog: MatDialog,
    private authService: MsalService,
    private userDetailService: FetchUserDetailsService,
  ) {
    this.dataSharingService.setDownArrow(true);
    if (localStorage.getItem("accessToken")) {
      this.dataSharingService.setUnrestrictedUser(false);
      this.unrestrictedUser = false;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (localStorage.getItem("accessToken")) {
        this.token = localStorage.getItem("accessToken");
        let decodedToken = jwt_decode(this.token);
        this.firstName = decodedToken["given_name"];
        this.lastName = decodedToken["family_name"];
        this.name = decodedToken['name'];
        this.uniqueName = decodedToken['unique_name'].includes('#') ? decodedToken['unique_name'].split("#").pop() : decodedToken['unique_name'];
        console.log(this.firstName, this.lastName);
      }
    }, 1000);
  }

  ngOnInit(): void {
    this.dataSharingService.isHeaderVisibility.subscribe((header) => {
      console.log("in constructor", header);
      this.isHeader = header;
      if (!header) {
        this.dataSharingService.fetchUserRole.subscribe((res: string) => {
        this.role = res;
        })
      }
    });
  }

  logout() {
    clearInterval(this.dataSharingService.refreshTokenTimer);
    this.dialog.open(PopUpContentComponent);
  }

  goBack() {
    this.route.navigate(['/login'])
   }
  
  openDelAcctPopUp() {
      console.log("del", this.delAcct);
      this.delAcct = true;
  }

  logoutMethodByDeleteAcct(): void {
    localStorage.removeItem("accessToken");
    sessionStorage.clear();
    this.authService.logout();
  }

  delUserAcct() {
    this.dataSharingService.setLoader(true);
    this.userDetailService.deleteUserAccount(this.uniqueName).subscribe((res => {
      this.dataSharingService.setLoader(false);
      if (res.status == true) {
        this.delAcct = false;
        this.logoutMethodByDeleteAcct();
        this.route.navigate(["/login"]);
      }
    }))
  }
    
}
@Component({
  selector: "popup-image",
  templateUrl: "logout-popup.html",
})
export class PopUpContentComponent {
  constructor(
    private route: Router,
    public dialogRef: MatDialogRef<PopUpContentComponent>,
    private authService: MsalService
  ) {}

  closePopup(): void {
    this.dialogRef.close();
  }

  logoutMethod(): void {
    localStorage.removeItem("accessToken");
    this.dialogRef.close();
    sessionStorage.clear();
    this.authService.logout();
    this.route.navigate(["/login"]);
  }
  ngOnInit(): void {}
}
