import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { from, Observable, of } from "rxjs";
import { IsendOthers, IspareParts, ProductDetails } from "../models/productDetails";
import { environment } from "../../environments/environment";
import { MsalService, BroadcastService } from "@azure/msal-angular";
import { Router, NavigationExtras } from "@angular/router";
import { catchError, map, mergeMap } from "rxjs/operators";
@Injectable({
  providedIn: "root",
})
export class ProductService {
  accessToken: any;
  constructor(
    private route: Router,
    private http: HttpClient,
    private authService: MsalService,
    private broadcastService: BroadcastService
  ) {}
  // setAccessToken(accessToken) {
  //   const now = new Date();
  //   const item = {
  //     value: accessToken,
  //     expiry: now.getTime() + 500000,
  //   };
  //   localStorage.setItem("accessToken", JSON.stringify(item));
  // }

  // getWithExpiry(): Observable<any> {
  //   const itemStr = localStorage.getItem("accessToken");
  //   if (!itemStr) {
  //     this.route.navigate(["/login"]);
  //     return of('');
  //   }

  //   const item = JSON.parse(itemStr);
  //   const now = new Date();
  //   if (now.getTime() > item.expiry) {
  //     localStorage.removeItem("accessToken");
  //     //try {
  //     return from(this.authService.acquireTokenPopup(OAuthSettings)).pipe(map((tokenResponse) => {
  //       console.log(tokenResponse.accessToken);
  //       this.setAccessToken(tokenResponse.accessToken);
  //       return tokenResponse.accessToken;
  //     }), catchError((error) => {
  //       console.log(error);
  //       this.route.navigate(["/login"]);
  //       return of('');
  //     }))
        
  //       // then(tokenResponse => {

  //       //   console.log(tokenResponse.accessToken);
  //       //   this.setAccessToken(tokenResponse.accessToken);
  //       //   return tokenResponse.accessToken;
  //       // }).catch((error) => {
  //       //   console.log(error);
  //       //   this.route.navigate(["/login"]);
  //       // }));
  //     //}
  //     // } catch(error) {
  //     //   console.log(error);
  //     //   this.route.navigate(["/login"]);
  //     // }
  //     // this.authService
  //     //   .acquireTokenPopup(OAuthSettings)
  //     //   .then((tokenResponse) => {
  //     //     console.log(tokenResponse.accessToken);
  //     //     this.setAccessToken(tokenResponse.accessToken);
  //     //     return tokenResponse.accessToken;
  //     //   })
  //     //   .catch(function (error) {
  //     //     console.log(error);
  //     //     this.route.navigate(["/login"]);
  //     //   });
  //   }
  //   return of(item.value);
  // }
  
  isUploadProgress(fileExtension): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}/product/isUploadProgress`, fileExtension);
  }
  fetchProductLines(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}productLine/listPipeLines`);
  }
  getProductLines(): Observable<any>{
    return this.http.get<any>(`${environment.apiUrl}listProductLines`)
  }

  getProductSubscription(userId, serialNo): Observable<any>{
    return this.http.get<any>(`${environment.apiUrl}product/${userId}/subscriptions?serialNo=${serialNo}`)
  }

  saveProductSubscription(userId, postData): Observable<any>{
    return this.http.post<any>(`${environment.apiUrl}product/${userId}/subscriptions`, postData);
  }

 updateProductSubscription(postData): Observable<any>{
    return this.http.put<any>(`${environment.apiUrl}product/subscriptions`, postData);
  }

  fetchProductDetails(serialNo): Observable<ProductDetails[]> {
    // return this.getWithExpiry().pipe(mergeMap((token) => {
    //   if (token) {
    //     token = "Bearer " + token;
    //     const body = JSON.stringify(serialNo);
    //     const headers = new HttpHeaders({ Authorization: token });
        return this.http.post<any>(`${environment.apiUrl}product/fetchProductDetails`, serialNo);
    //   }
    // }))
    // const itemStr = localStorage.getItem("accessToken");
    // if (!itemStr) {
    //   const body = JSON.stringify(serialNo);
    //   return this.http.post<any>(`${environment.apiUrl}product/fetchProductDetails`, serialNo);
    // } else {
    //   const item = JSON.parse(itemStr);
    //   const now = new Date();
    //   if (now.getTime() > item.expiry) {
    //     localStorage.removeItem("accessToken");
    //     this.authService
    //       .acquireTokenPopup(OAuthSettings)
    //       .then((tokenResponse) => {
    //         console.log(tokenResponse.accessToken);
    //         this.setAccessToken(tokenResponse.accessToken);
    //         let token = "Bearer " + tokenResponse.accessToken;
    //         const body = JSON.stringify(serialNo);
    //         const headers = new HttpHeaders({ Authorization: token });
    //         return this.http.post<any>(`${environment.apiUrl}product/fetchProductDetails`, serialNo, { headers: headers }
    //         );
    //       })
    //       .catch(function (error) {
    //         console.log(error);
    //         this.route.navigate(["/login"]);
    //       });
    //   } else {
    //     let token = "Bearer " + item.value;
    //     const body = JSON.stringify(serialNo);
    //     const headers = new HttpHeaders({ Authorization: token });
    //     return this.http.post<any>(
    //       `${environment.apiUrl}product/fetchProductDetails`,
    //       serialNo,
    //       { headers: headers }
    //     );
    //   }
    // }
  }

  listProductDetails(serialNo): Observable<ProductDetails[]> {
        return this.http.post<any>(`${environment.apiUrl}listProductDetails`, serialNo);
  }


  fetchqrCodeDetails(qrCodeId): Observable<ProductDetails[]> {
    return this.http.post<any>(`${environment.apiUrl}product/fetchProductDetailsbyQR`, qrCodeId);
  }

  fetchProductNotes(serialNo): Observable<any> {
    const body = JSON.stringify(serialNo);
    return this.http.post<any>(`${environment.apiUrl}notes/getProductNotes`, serialNo);
  }

  fetchSpareParts(serialNo): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}getspareparts?serialNo=${serialNo}`);
  }
  
  getRole(): Observable<any> {
    // return this.getWithExpiry().pipe(mergeMap((token) => {
    //   token = "Bearer " + token;
    //   const headers = new HttpHeaders({ Authorization: token });
      return this.http.get<any>(`${environment.apiUrl}getUserRole`);
    // }))
  }

  // sendSPQuotes(sparePartsQuote: IspareParts, productFirstAttributes: string): Observable<any> {
  //   return this.http.post<any>(`${environment.apiUrl}sendquote`, {spareParts: sparePartsQuote, productFirstAttribute: productFirstAttributes});


  sendSPQuotes(sparePartsQuote: IspareParts): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}sendquote`, sparePartsQuote);
  }

  sendSPOrder(sparePartsOrder: IspareParts): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}sendorder`, sparePartsOrder)
  }

  addProductNotes(objectData): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}notes/addProductNotes`, objectData);
  }

  deleteProductNotes(deleteNote): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}product/deleteProductNotes`, deleteNote);
  }

  grantAccess(prodlineId: string, customerAccountNo: string, serialNo: string): Observable<any> {
    let formData: FormData = new FormData();
    formData.append("productLineId", prodlineId);
    formData.append("customerAccountNo", customerAccountNo);
    formData.append("serialNo", serialNo);
    return this.http.post<any>(`${environment.apiUrl}product/grantAccessRequest`, formData);
  }

  checkQuoteandOrder(productLineName: string): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}checkQuoteandOrder/${productLineName}`);
  }

  searchByProductLine(productLine: string, year: any, month: any, searchType: string): Observable<any> {
    const url = month ? `${environment.apiUrl}productquoteorder?productLineId=${productLine}&month=${month}&year=${year}&searchType=${searchType}` : `${environment.apiUrl}productquoteorder?productLineId=${productLine}&year=${year}&searchType=${searchType}`;
    return this.http.get<any>(url);
  }

  searchBySerialNo(serialNo, month: any, year: any, searchType: string): Observable<any> {
    const url = month ? `${environment.apiUrl}productquoteorder?serialNo=${serialNo}&month=${month}&year=${year}&searchType=${searchType}` : `${environment.apiUrl}productquoteorder?serialNo=${serialNo}&year=${year}&searchType=${searchType}`;
    return this.http.get<any>(url);
  }
  
  getTextFieldData(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}emailtemplates`)
  }

  updateTextFieldData(productLine: string, postData: any): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}emailtemplates/${productLine}`, postData);
  }

  productSplitDetailsByProductLine(productLine: string, year: any, month: any, searchType: string, quoteOrOrder: string, currency: string): Observable<any> {
    const url = month ? `${environment.apiUrl}productquoteorders/productline?productLineId=${productLine}&month=${month}&year=${year}&searchType=${searchType}&quoteOrOrder=${quoteOrOrder}&currency=${currency}` : `${environment.apiUrl}productquoteorders/productline?productLineId=${productLine}&year=${year}&searchType=${searchType}&quoteOrOrder=${quoteOrOrder}&currency=${currency}`
    return this.http.get<any>(url);
  }

  productSplitDetailsBySerialNo(serialNo, month: any, year: any, searchType: string, quoteOrOrder: string, currency: string): Observable<any> {
    const url = month ? `${environment.apiUrl}productquoteorders/serialno?serialNo=${serialNo}&month=${month}&year=${year}&searchType=${searchType}&quoteOrOrder=${quoteOrOrder}&currency=${currency}` : `${environment.apiUrl}productquoteorders/serialno?serialNo=${serialNo}&year=${year}&searchType=${searchType}&quoteOrOrder=${quoteOrOrder}&currency=${currency}`;
    return this.http.get<any>(url);
  }

  getAllProductLine():Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}productlines`);
  }

  addProductLine(plData: any): Observable<any>{
    return this.http.post<any>(`${environment.apiUrl}productlines`, plData);
  }

  updateProductLine(productLineId: number, plData: any): Observable<any>{
    return this.http.put<any>(`${environment.apiUrl}productlines/${productLineId}`, plData);
  }

  getAuditLogsData(pageNo: number, pageSize: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}auditlogs?page=${pageNo}&size=${pageSize}`)
  }

  getSparePartRefDocs(productLine: string): Observable<any> {
    return this.http.get<any> (`${environment.apiUrl}getSparePartRefDocs?productLine=${productLine}`)
  }

  deleteSparePartRefDocs(productLine: string, fileName: string): Observable<any> {
    console.log("fn1", fileName);
    return this.http.delete<any>(`${environment.apiUrl}deleteSparePartRefDocs?productLine=${productLine}&fileName=${fileName}`);
  }

  sendOthersToSpareParts(postData: IsendOthers): Observable<any> {
    return this.http.post<any>(`${environment.apiUrl}sendothers`, postData);
  }

  getExchangeRateData(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}exchangerate`)
  }

  updateExchangeRateData(productLine: string, postData: any): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}exchangerate/${productLine}`, postData);
  }

}
