import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductService } from '../services/product.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileUploadService } from '../services/file-upload.service';
import { DataSharingService } from '../services/data-sharing.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export interface DialogData {
  productId: string;
  productLine: any;
  notes: any;
}
@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.css']
})
export class AddNoteComponent implements OnInit {
  addNoteForm: FormGroup;
  public isHidden = true;
  fileName: any;
  addNotes = {
    productNotes: {
      productSerialNo: null,
      productNotes: null,
      imageLoc: null,
      updatedBy: { operatorId: 0 },
      updatedOn: null,
      isDeleted: null,
      deletedBy: null,
    },
    productNotesFile: [{
      filepath: null,
      fileType: null,
    }]
  };

  myDate = new Date();
  lastSlashValue: any;
  productNotesFile: [];
  submitted = false;
  loading = false;
  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<AddNoteComponent>,
              @Inject(MAT_DIALOG_DATA) public data: DialogData, private productService: ProductService,
              private snackBar: MatSnackBar, private fileUploadService: FileUploadService,
              private dataSharingService: DataSharingService) {
  }

  ngOnInit(): void {
    this.addNoteForm = this.formBuilder.group({
      notes: ['', Validators.required],
      // password: ['', Validators.required]
    });
  }

  closePopup(): void {
    this.dialogRef.close();
  }

  /**
   * Name : Sandhya Misal
   * Functionality : Calling file upload and add note api
   * Code Desc : It is saving the note which is entered by admin and seleted file  by calling add note api.
   */

  get f() { return this.addNoteForm.controls; }

  SaveNote(files, text) {
    this.submitted = true;
    if (this.addNoteForm.invalid) {
      return;
    }

    this.loading = true;

    if (files) {
      console.log('Entered in if condition');
      console.log('this.data.notes;', this.data.notes);
      console.log('File:', files);
      this.fileName = files._fileNames;
      this.addNotes.productNotes.productSerialNo = this.data.productId;
      this.addNotes.productNotes.productNotes = text;
      console.log('this.addNotes.productNotes.productNotes', this.addNotes.productNotes.productNotes);
      this.addNotes.productNotes.updatedBy.operatorId = 2;
      console.log('myDate:', this.myDate);
      this.addNotes.productNotes.updatedOn = this.myDate;
      this.addNotes.productNotes.imageLoc = '';
      this.addNotes.productNotes.isDeleted = 0;
      this.addNotes.productNotes.deletedBy = null;
      this.fileUploadService.fileUploadForNotes(files, this.data.productLine, this.data.productId).subscribe(data => {
        console.log('Response from api', data);
        this.addNotes.productNotesFile = [];

        if (data.Message === 'Success') {
          console.log('length of files._files', files._files.length);
          files._files.map(item => {
            return {
              filepath: data.Upload_URL + '/' + item.name,
              fileType: item.type
            };
          }).forEach(item => this.addNotes.productNotesFile.push(item));

          console.log('this.addNotes.productNotesFile', this.addNotes.productNotesFile);
        }
        this.productService.addProductNotes(this.addNotes).subscribe(resp => {
          console.log('data', resp);
          if (data.Message === 'Success') {
            this.snackBar.open('New note has been added successfully', '', {
              duration: 3500
            });
            this.dataSharingService.setNotes(true);
          }
          else {
            this.snackBar.open('error in adding notes', '', {
              duration: 3500
            });
          }
        }, (err) => {
          console.log(err);
        });


      }, (err) => {
        console.log(err);
      });
    }
    else {
      this.addNotes.productNotes.productSerialNo = this.data.productId;
      this.addNotes.productNotes.productNotes = text;
      console.log('this.addNotes.productNotes.productNotes', this.addNotes.productNotes.productNotes);
      this.addNotes.productNotes.updatedBy.operatorId = 2;
      console.log('myDate:', this.myDate);
      this.addNotes.productNotes.updatedOn = this.myDate;
      this.addNotes.productNotes.imageLoc = '';
      this.addNotes.productNotes.isDeleted = 0;
      this.addNotes.productNotes.deletedBy = null;
      this.addNotes.productNotesFile = [];
      this.productService.addProductNotes(this.addNotes).subscribe(data => {
        console.log('data', data);
        if (data.Message === 'Success') {
          this.snackBar.open('New note has been added successfully', '', {
            duration: 3500
          });
          this.dataSharingService.setNotes(true);
        }
        else {
          this.snackBar.open('error in adding notes', '', {
            duration: 3500
          });
        }
      }, (err) => {
        console.log(err);
      });
    }
    this.dialogRef.close();
  }

}
