<div>
    <p class="f-m-s-b-14">Add Product Note</p>
    <img class="closeIcon" (click)="closePopup()" src="../../assets/close.png" />
</div>
<hr style="margin-top: -25px;">
<img src="../../assets/note_16w.png" style="max-width: 5%;"/>
<p class="textAlign">You can add notes on product and attach photos and files here...</p>
<form [formGroup]="addNoteForm" class="md-form" (ngSubmit)="SaveNote(uploader.value, text.value)">
    <div class="form-group">
        <textarea formControlName="notes" rows="2" class="form-control textarea" placeholder="Enter your notes here..."
            #text [ngClass]="{ 'is-invalid': submitted && f.notes.errors }"></textarea>
        <div *ngIf="submitted && f.notes.errors" class="invalid-feedback">
            <div *ngIf="f.notes.errors.required">Notes field is required</div>
        </div>
    </div>
    <div class="file-field">
        <mat-form-field style="width: 97%;">
            <button class="browseButton" matSuffix>Browse</button>
            <ngx-mat-file-input class="selectFile" placeholder="Select file to upload" #uploader multiple>
            </ngx-mat-file-input>
        </mat-form-field><br>
    </div>
    <button class="cancelButton" (click)="closePopup()"> Cancel</button>
    <button [disabled]="loading" class="saveButton"> Save Note</button>
</form>