import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { OAuthSettings } from 'src/oauth';
import { ProductService } from './product.service';

@Injectable({
  providedIn: 'root'
})
export class DataSharingService {
  private userTypeId: number;
  month: String[];
  year: string[];
  country: string[];
  refreshTokenTimer: any;

  private showSmallLoader = false;
  private _subscriberId = "";

  constructor(private productService: ProductService, private authService: MsalService) { 
     this.month = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    this.year = ['2022', '2023', '2024', '2025', '2026'];
    this.country = ['Germany', 'USA', 'India', 'Great Britain']
  }
  fetchUserRoleFromServer() {
    return this.productService.getRole().pipe(map((data) => {
      if (!data) {
        return '';
      }
       let role = data.find((element: string) => element === 'Admin');
      if (!role) {
        role = data.find((element: string) => element === 'User');
      } 
      this.userRole.next(role);
      return role;
    }),
      catchError((error) => {
        return of('');
      })
    )
  }

  refreshAccessToken() {
    this.refreshTokenTimer = setInterval(() => {
      this.authService.acquireTokenSilent(OAuthSettings).then((tokenResponse) => {
        console.log(tokenResponse.accessToken);
        this.setAccessToken(tokenResponse.accessToken);
    }).catch((error) => {
      console.log(error);
      clearInterval(this.refreshTokenTimer);
    });
    }, 400000);
  }

  setAccessToken(accessToken){
    const now = new Date();
    const item = {
      value: accessToken,
      expiry: now.getTime() + 500000,
    }
    localStorage.setItem("accessToken", JSON.stringify(item));
  }
  setShowSmallLoader(value: any) {
    this.showSmallLoader = value;
  }

  getShowSmallLoader() {
    return this.showSmallLoader;
  }

  userRole = new BehaviorSubject(null);
  fetchUserRole = this.userRole.asObservable().pipe(tap((value)=> {if(!value){this.fetchUserRoleFromServer().subscribe()} }));

  private loader = new BehaviorSubject(false);
  fetchLoader = this.loader.asObservable();

  private pendingArray = new BehaviorSubject(null);
  fetchPendingArray = this.pendingArray.asObservable();

  private grid = new BehaviorSubject(null);
  gridValue = this.grid.asObservable();

  private faq = new BehaviorSubject(null);
  faqs = this.faq.asObservable();

  private headerVisibility = new BehaviorSubject(true);
  isHeaderVisibility = this.headerVisibility.asObservable();

  private unrestrictedUser = new BehaviorSubject(null);
  unrestrictedUserAccess = this.unrestrictedUser.asObservable();

  private notes = new BehaviorSubject(null);
  fetchNotes = this.notes.asObservable();

  private downArrow = new BehaviorSubject(true);
  isDownArrow = this.downArrow.asObservable();


  fetchPendingData(data) {
    this.pendingArray.next(data);
  }

  setGrid(value) {
    this.grid.next(value);
  }

  setFaq(value) {
    this.faq.next(value);
  }

  setHeader(value) {
    this.headerVisibility.next(value);
  }

  setDownArrow(value) {
    this.downArrow.next(value);
  }

  setUnrestrictedUser(value) {
    this.unrestrictedUser.next(value);
  }
  setNotes(value) {
    this.notes.next(value);
  }

  getLoader() {
    return this.fetchLoader;
  }

  setLoader(value: any) {
    this.loader.next(value);
  }

  setUserTypeId(value: any) {
    this.userTypeId = value;
  }

  getUserTypeId() {
    return this.userTypeId;
  }

  getSubscribtionId() {
    return this._subscriberId;
  }
  setSubscribtionId(value: any) {
    this._subscriberId = value;
  }
  
}
