
<div class="header" >
    <div class="logo-left" [routerLink]="['/login']">
        <img src="../../assets/logo.svg" alt="Circor"/>
    </div>
</div>

<div class="mtp22">
    <form [formGroup]="addregisterForm" (ngSubmit)="onSubmit()">
    <div class="d-flex userdetails-prop">
    <h1>Create An Account</h1>
        <div class="d-flex">
            <div class="form-grp">
                <div class="wd100f">
                    <label>First Name <span class="md_field">*</span></label>
                    <input type="text" placeholder="Please enter a First name"  formControlName="firstName"  [ngClass]="{ 'is-invalid':  registerForm.firstName.errors }"/>
                    <div class="invalid-feedback" *ngIf="registerForm.firstName.invalid && (registerForm.firstName.dirty || registerForm.firstName.touched)">First Name is required</div>
                </div>
            </div>
            <div class="form-grp">
                <div class="wd100f">
                    <label>Last Name <span class="md_field">*</span></label>
                    <input type="text" placeholder="Please enter a First name" formControlName="lastName"  [ngClass]="{ 'is-invalid':  registerForm.lastName.errors }"/>
                    <div class="invalid-feedback" *ngIf="registerForm.lastName.invalid && (registerForm.lastName.dirty || registerForm.lastName.touched)">Last Name is required</div>
                </div>
            </div>

            <div class="form-grp">
                <div class="wd100f">
                    <label>Email <span class="md_field">*</span></label>
                    <input type="text" placeholder="Please enter a First name" formControlName="email" class="is-invalid"/>
                    <div class="invalid-feedback" *ngIf="registerForm.email.errors?.required && (registerForm.email.dirty || registerForm.email.touched)">Email  is required</div>
                    <div class="invalid-feedback" *ngIf="registerForm.email.errors?.pattern && (registerForm.email.dirty || registerForm.email.touched)">Email address is invalid</div>
                </div>
            </div>

        </div>
        <div class="d-flex mtpb22">
            <div class="form-grp">
                <div class="wd100f">
                <label>Mobile No <span class="md_field">*</span></label>
                <input type="number" placeholder="Please enter a Phone number" formControlName="mobileNo" class="is-invalid"/>
                <div class="invalid-feedback" *ngIf="registerForm.mobileNo.invalid && (registerForm.mobileNo.dirty || registerForm.mobileNo.touched)">
                    Mobile No is required 
                </div>
                </div>
            </div>

            <div class="form-grp">
                <div class="wd100f">
                <label>Brand <span class="md_field">*</span></label>
                <select formControlName="brand" class="is-invalid">
                    <option *ngFor="let k of brandArr" [value]="k.value">{{k.value}}</option>
                </select>
                <div class="invalid-feedback" *ngIf="registerForm.brand.invalid && (registerForm.brand.dirty || registerForm.brand.touched)">
                    Brand is required 
                </div>
            </div>
            </div>

            <div class="form-grp">
                <div class="wd100f">
                <label>Company Name <span class="md_field">*</span></label>
                <input type="text" placeholder="Please enter a Company name" formControlName="orgName" class="is-invalid"/>
                <div class="invalid-feedback" *ngIf="registerForm.orgName.invalid && (registerForm.orgName.dirty || registerForm.orgName.touched)">
                    companyName is required 
                </div>
                </div>
            </div>
        </div>
        <div class="d-flex">
            <div class="form-grp">
                <div class="wd100f">
                <label>Department</label>
                <input type="text" placeholder="Please choose a Department name" formControlName="department"/>
                </div>
            </div>
            <div class="form-grp">
                <div class="wd100f">
                <label>Location</label>
                <input type="text" placeholder="Please choose a Location" formControlName="location"/>
                 </div>
            </div>

            <div class="form-grp">
                <div class="wd100f">
                <label>Job Title</label>
                <input type="text" placeholder="Please enter a Job Title" formControlName="jobTitle"/>
                </div>
            </div>
        </div>
     
    </div>

    <div class="grid-btn prop">
        <button class="btn" (click)="goBack()">BACK</button>
        <button class="btn right-space" type="submit" [disabled]="!addregisterForm.valid">REGISTER</button>
    </div>
    </form>
  <!-- *ngIf="registerAccountHolder" -->
 
    <div *ngIf="registerAccountHolder">
        <span class="regAccHldr_txt">Your Email id has been registered already but account status is Inactive, if you need to re-activate the same account, please input the below detail and click on send. <br/>We will check and active your CIRCOR Smartapp account within 3 days </span>
        
        <div class="d-flex userdetails-prop">
        <div class="d-flex mtp26">
            <div class="form-grp">
                <div class="wd100f">
                    <label>Email <span class="md_field">*</span></label>
                    <input type="text" placeholder="Please enter a First name" [(ngModel)]="emailAcctStatus" class="is-invalid"/>
                    <!-- <div class="invalid-feedback" *ngIf="registerFormAcctStatus.emailAcctStatus.errors?.required && (registerFormAcctStatus.emailAcctStatus.dirty || registerFormAcctStatus.emailAcctStatus.touched)">Email  is required</div>
                    <div class="invalid-feedback" *ngIf="registerFormAcctStatus.emailAcctStatus.errors?.pattern && (registerFormAcctStatus.emailAcctStatus.dirty || registerFormAcctStatus.emailAcctStatus.touched)">Email address is invalid</div> -->
                </div>
            </div>
            <div class="form-grp">
                <div class="wd100f">
                  
                </div>
            </div>

            <div class="form-grp">
                <div class="wd100f">
                    
                </div>
            </div>

        </div>
    </div>

    <div class="grid-btn prop1">
        <button class="btn right-space" type="submit" (click)="useraccountreactivate()">SEND</button>
    </div>

    </div>

<div class="overlay" *ngIf="postFormSubmit">
    <div class="overlay_hldr">
    <span class="close_icon" (click)="postFormSubmit = !postFormSubmit">X</span>
    <span *ngIf="isError">{{isError}}</span>
    <span *ngIf="!isError">{{isError}}</span>
    <span class="grid-btn">
    <button class="btn" (click)="postFormSubmit = !postFormSubmit">Close</button></span>
</div>
</div>

</div>

<div class="row text-center footer m-0">
    <div class="col-md-12">
    © CIRCOR INTERNATIONAL, INC. ALL RIGHTS RESERVED, 2020.
    </div>
</div>