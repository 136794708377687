<div class="imPage">
    <div class="row breadcrumb-san">
    <div *ngIf = "!unrestrictedUser" class="wd99p">
        
<div class="row dis-hldr">
    <div class="breadcrumb-sa">
        <span class="breadcrumb-link" (click)="ShowDashboardPage()"> Dashboard - </span><span class="headerTextAllignment">Documents and Links</span>
    </div>

    <div class="d-flex sp-even"> 
    <div class="d-flex">
    <button class="FAQButton" (click)="ShowFAQPage()"><img src="../../../assets/faq.png">  FAQ</button>
    </div>
    <div class="d-flex">
    <button class="productNotesButton" (click)="ShowProductDetailPage()"><img src="../../assets/note_16w.png"> Product Notes</button></div>
</div>
</div>
</div>

<div *ngIf = "unrestrictedUser" class="wd99p">
<div class="row dis-hldr">
    <div class="breadcrumb-sa"> <span class="breadcrumb-text">Documents and Links</span></div>

    <div class="d-flex sp-even"> 
        <div class="d-flex">
    <button class="FAQButton" (click)="ShowFAQPage()"><img src="../../../assets/faq.png">  FAQ</button></div>
    <div class="d-flex">
        <button class="instructionMannualButton" (click)="ShowProductDetailPage()"><img src="../../assets/note_16w.png"> Product Notes</button></div>
    </div>
</div>
</div>
</div>
        <div class="row mainPage">
            <div class="col-lg-4">
                <div class="card productDetails">
                    <div class="card-body">
                        <h4 class="productDetailsContainerText">PRODUCT DETAILS</h4>
                        <div class="text-center">
                            <div *ngIf="loader" class="spinner-border m-5" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <P class="productValue">{{productDetailObject.productLineId}}</P>
                                <p *ngIf="!loader" class="product">PRODUCT LINE</p>
                            </div>
                            <div class="col-12">
                                <p class="productValue">{{productDetailObject.serialNumber}}</p>
                                <p *ngIf="!loader" class="product">SERIAL NO</p>
                            </div>
                            <div class="col-12">
                                <p class="productValue">{{productDetailObject.cliTagNo}}</p>
                                <p *ngIf="!loader" class="product">CLIENT TAG NO</p>
                            </div>
                        </div>
                        <hr *ngIf="!loader" class="dashedLine">
                        <div class="row">
                            <div class="col-6" *ngFor="let product of productDetailObject.prodAttributes">
                                <p class="productValue">{{product.attributeValue}}</p>
                                <p class="product">{{product.attributeId.attributeName}}</p>
                            </div>
                        </div>
                        <div *ngIf="!loader">
                            <hr class="dashedLine">
                            <div class="row">
                                <div class="col-12" style="font-weight: 500;">
                                    CONTACT DETAILS
                                </div>
                            </div>
                            <div class="row contactDetailsBlock">
                                <div class="product contactText col-5">
                                    Contact Name :
                                </div>
                                <div class="attributeValue col-7">
                                    <p>{{productDetailObject.contName1}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="product contactText col-5">
                                    Phone Number :
                                </div>
                                <div class="attributeValue col-7">
                                    <p>{{productDetailObject.contPhone1}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="product contactText col-5">
                                    Email Address/Link:
                                </div>
                                <div class="attributeValue col-7">
                                    <p *ngIf="validateEmail1 ; else elseBlock">{{productDetailObject.contEmail1}}</p>  
                                    <ng-template #elseBlock>
                                        <a *ngIf="validateURL1" [routerLink]="" (click)="openLink(productDetailObject.contEmail1)">Find a Local CIRCOR Representative</a>
                                        <p *ngIf="!validateURL1">{{productDetailObject.contEmail1}}</p>
                                    </ng-template>   
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="product contactText col-5">
                                    Contact Name :
                                </div>
                                <div class="attributeValue col-7">
                                    <p>{{productDetailObject.contName2}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="product contactText col-5">
                                    Phone Number :
                                </div>
                                <div class="attributeValue col-7">
                                    <p>{{productDetailObject.contPhone2}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="product contactText col-5">
                                    Email Address/Link:
                                </div>
                                <div class="attributeValue col-7">
                                        <p *ngIf="validateEmail2; else elseblock2">{{productDetailObject.contEmail2}}</p>  
                                        <ng-template  #elseblock2>
                                            <a *ngIf="validateURL2" [routerLink]="" (click)="openLink(productDetailObject.contEmail2)">Find a Local CIRCOR Representative</a>
                                            <p *ngIf="!validateURL2">{{productDetailObject.contEmail2}}</p>
                                        </ng-template> 
                                </div>
                            </div>
                        </div>
                        
                       
                      
                        <div *ngIf="!loader">
                            <hr class="dashedLine">
                                <div *ngIf="isgetSpareParts && isUserType()">
                                    <div class="row">
                                <div class="col-12" style="font-weight: 500;">
                                    SPARE PARTS
                                </div>
                            </div>
                            <div>
                            <div class="d_flex mtp5">
                                <div class="d_flex sp_flex1">
                                <span class="width8p" *ngIf="CheckBoxVisibleForQuoteOrder() && this.sparePartsResult.length > 0" ><input type="checkbox" [(ngModel)]="isselectAll" (change)="selectAll(isselectAll)"/></span>
                                <span class="width32p">Description </span>
                                <span class="width22p">Part No</span>
                                <span class="width18p">Price</span>
                                <span class="width20p">Currency</span>
                                <span *ngIf="isOrgPrice" class="width22p">MRRP</span>
                                </div>

                                <div class="d_flex sp_flex" *ngFor="let spareParts of sparePartsResult"> 
                                    <span class="width8p" *ngIf="CheckBoxVisibleForQuoteOrder() && this.sparePartsResult.length > 0" ><input type="checkbox" (change)="checkForAllSelect()" [(ngModel)]="spareParts.checked"/></span>
                                    <span class="width32p">{{spareParts.description}}</span>
                                    <span class="width22p">{{spareParts.partNo}}</span>
                                    <span class="width18p">{{spareParts.discountPrice | numberFormatter}}</span>
                                    <span class="width20p">{{spareParts.currency}}</span>
                                    <span *ngIf="isOrgPrice" class="width22p">{{spareParts.price | numberFormatter}}</span>
                                </div>
                                
                            </div>
                            <div class="mtp10">
                                <button class="quote" *ngIf="this.isCheckQuoteOrder && this.isCheckQuoteOrder.quoteAdmin" (click)="sparePartsQuotesAndOrder('quote')">Quote</button>
                                <button class="order" *ngIf="this.isCheckQuoteOrder && this.isCheckQuoteOrder.orderAdmin" (click)="sparePartsQuotesAndOrder('order')">Order</button>
                                <button class="sendothers" *ngIf="this.isCheckQuoteOrder && role === 'Admin'" (click)="sendOthers()">Send Others</button>
                            </div>
                        </div>
                        </div>
                    
                            </div>
                        </div>

                </div>

                <div >
                    <div >
                    </div>

                </div>
            </div>
            <div class="col-lg-8">
                <img class="imgArrow" src="../../assets/arrow.png" (click)="gotoProductDetails()">
                <h4 class="miscellaneousTitle f-m-s-b">Documents and Links</h4>
                <div *ngIf="showGrantAccessButton()">
                    <button class="btn btn-info" (click)="grantAccessRequest()">Request Access to Documents</button>
                </div>
                <br/>
                <div class="row" *ngIf="instVideo?.length > 0">
                    <div class="col-8">
                        <div class="card manualVideo">
                            <div class="card-body">
                                <p class="installationPage f-m-r">INSTRUCTION VIDEOS</p>
                                <a href={{instVideo}} class="productValue" target="_blank">{{instVideo}}</a>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="maintVideo?.length > 0">
                    <div class="col-8">
                        <div class="card manualVideo">
                            <div class="card-body">
                                <!-- *ngIf="maintVideo.length > 0" -->
                                <p class="installationPage f-m-r" >MAINTENANCE VIDEOS</p>
                                <a href={{maintVideo}} class="productValue" target="_blank">{{maintVideo}}</a>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row"  *ngIf="isMessage">
                    <div class="col-8">
                        <div class="card manual">
                            <div class="card-body"> 
                                <p class="installationPage f-m-r">INSTALLATION OPERATING MANUAL</p>
                                <div class="pdf_hldr"  *ngFor = "let pdf of prodDoc">
                                    <div class="cp glb_chkbox_align" (click)="downloadFile(pdf.doclocation)">
                                        <img src="../../assets/PDF.png" />
                                        <span>{{pdf.fileName}}</span>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="dataSheet">
                    <div class="col-8">
                        <div class="card manual">
                            <div class="card-body">
                                <p class="installationPage f-m-r">DATA SHEET</p>
                                <div class="pdf_hldr"  *ngFor = "let pdf of dataSheetDoc">
                                    <div class="cp glb_chkbox_align" (click)="downloadFile(pdf.doclocation)">
                                     <img src="../../assets/PDF.png"/>
                                     <span>{{pdf.fileName}}</span>
                                    </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="dataBook">
                    <div class="col-8">
                        <div class="card manual">
                            <div class="card-body">
                                <p class="installationPage f-m-r">DATA BOOK</p>
                                <div class="pdf_hldr"  *ngFor = "let pdf of dataBookDoc">
                                    <div class="cp glb_chkbox_align" (click)="downloadFile(pdf.doclocation)">
                                     <img src="../../assets/PDF.png"/>
                                     <span>{{pdf.fileName}}</span>
                                    </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="weldingSheet">
                    <div class="col-8">
                        <div class="card manual">
                            <div class="card-body">
                                <p class="installationPage f-m-r">WELDING</p>
                                <div class="pdf_hldr" *ngFor = "let pdf of weldingSheetDoc">
                                    <div class="cp glb_chkbox_align" (click)="downloadFile(pdf.doclocation)">
                                     <img src="../../assets/PDF.png"/>
                                     <span>{{pdf.fileName}}</span>
                                    </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="row" *ngIf="isSpares">
                    <div class="col-8">
                        <div class="card manualVideo">
                            <div class="card-body">
                                <p class="installationPage f-m-r">SPARES PARTS</p>
                                <div class="pdf_hldr"  *ngFor = "let pdf of spareDoc">
                                    <div class="cp glb_chkbox_align" (click)="downloadFile(pdf.doclocation)">
                                        <img src="../../assets/PDF.png"/>
                                        <span>{{pdf.fileName}}</span>
                                     </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="otherSheet">
                    <div class="col-8">
                        <div class="card manual">
                            <div class="card-body">
                                <p class="installationPage f-m-r"></p>
                                <div class="pdf_hldr" *ngFor = "let pdf of othersDoc">
                                    <div class="cp glb_chkbox_align" (click)="downloadFile(pdf.doclocation)">
                                     <img src="../../assets/PDF.png"/>
                                     <span>{{pdf.fileName}}</span>
                                    </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row"  *ngIf="isGlobalPDFs">
                    <div class="col-8">
                        <div class="card manualVideo">
                            <div class="card-body">
                                <p class="installationPage f-m-r">GLOBAL DOCUMENTS</p>
                                <div class="pdf_hldr" *ngFor="let pdf of globalDocument">
                                    <div class="cp glb_chkbox_align" (click)="downloadGlobalPDF(pdf.doclocation)">
                                        <img src="../../assets/PDF.png"/>
                                        <span>{{pdf.fileName}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
            </div>
        </div>
        </div>
      