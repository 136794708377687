<header ></header>
    <div *ngIf="showLoader" id="loader" class="d-flex justify-content-center align-items-center"><img src="assets/loader.gif"/></div>
<router-outlet></router-outlet>
<footer></footer>



 <!-- <div>
    <div style="display: flex; justify-content: center; align-items: center; height: 90vh">
        <div style="flex-direction: column; text-align: center;">
            <img src="../assets/web-maintenance.png" style="width: 7%; padding-bottom: 25px;"/>
            <h1 style="font-size: 35px; padding-bottom: 3px;">We’ll be back soon!</h1>
            <p style="font-size: 19px;">Our web application is currently undergoing scheduled maintenance. We should be back shortly. Thanks for your patience.</p>
            <img src="../assets/circor_smart_app_logo.png" style="width: 24%; padding-top: 36px;"/><br/>
            <p style="font-size: 18px; padding-top: 5px; ">&mdash; The CIRCORSmart Team &mdash;</p>

        </div>
    </div>
</div> -->