<div class="productDetailPage">

<div class="row breadcrumb-san">
    <div *ngIf="!unrestrictedUser" class="wd99p">
   
    <div class="row dis-hldr">
    <div class="breadcrumb-sa"><span class="breadcrumb-link" (click)="ShowDashboardPage()">Dashboard - </span><span class="breadcrumb-text">PRODUCT DETAILS</span>
    </div>
    <div class="d-flex sp-even">
    <div class="d-flex">
    <button class="FAQButton"(click)="ShowFAQPage()"><img class="faqImg"src="../../assets/faq.png" (click)="ShowFAQPage()"> FAQ</button>
    </div>
    <div class="d-flex">
    <button class="instructionMannualButton"(click)="ShowInstructionalManualPage()"><img src="../../../assets/manual.png"> Documents and Links</button>
    </div>
    <div class="d-flex align-items-centers"><a
    href="https://apps.apple.com/in/app/circorsmart/id1540756845">
    <img class="appStoreImage"src="../../../assets/AppStore.png" alt="APP Store Download" title="APP Store Download"></a>
    </div>
    <div class="d-flex align-items-centers"><a
    href="https://play.google.com/store/apps/details?id=com.circor.pipelineapp">
    <img class="playStoreImage"src="../../../assets/GooglePay.png" alt="Play Store Download" title="Play Store Download"></a>
    </div>
    </div>
    </div>
    </div>
    <div *ngIf="unrestrictedUser" class="wd99p">
    <div class="row dis-hldr">
    <div class="breadcrumb-sa"><span class="breadcrumb-text">PRODUCT DETAILS</span>
    </div>
    <div class="d-flex sp-even">
    <div class="d-flex">
    <button class="FAQButton"(click)="ShowFAQPage()"><img class="faqImg"src="../../assets/faq.png" (click)="ShowFAQPage()"> FAQ</button>
    </div>
    <div class="d-flex">
    <button class="instructionMannualButton"(click)="ShowInstructionalManualPage()"><img src="../../../assets/manual.png"> Documents and Links</button>
    </div>
    <div class="d-flex align-items-centers"><a href="https://apps.apple.com/in/app/circorsmart/id1540756845">
    <img class="appStoreImage"src="../../../assets/AppStore.png"  alt="APP Store Download" title="APP Store Download"></a>
    </div>
    <div class="d-flex align-items-centers"><a href="https://play.google.com/store/apps/details?id=com.circor.pipelineapp">
    <img class="playStoreImage"src="../../../assets/GooglePay.png"  alt="Play Store Download" title="Play Store Download"></a>
    </div>
</div>
    </div>
    </div>
    </div>

   

      <!-- <div style="margin-right: 10%;"> -->
        <div class="row mainPage">
            <div class="col-lg-4">
                <div class="card productDetails">
                    <!-- <img src="../../../assets/Background pattern.png"> -->
                    <div class="card-body">
                       <div class="d-flex justify-content-sb">
                        <h4 class="productDetailsContainerText">PRODUCT DETAILS</h4>
                        <!-- need <div class="d-flex">
                            <span *ngIf="firstSubscribeBtn" class="redbtn" (click)="sendOSTag(productDetailObject?.serialNumber)">Subscribe this Serial No</span>
                            <span *ngIf="unsubscribeBtn" class="redbtn" (click)="deleteOSTag()">Unsubscribe this Serial No </span>
                            <span *ngIf="subscribeBtn" class="redbtn" (click)="updateOSTag(productDetailObject?.serialNumber)">Subscribe this Serial No</span>
                        </div> -->
                    </div>
                        <div class="text-center">
                            <div *ngIf="loader" class="spinner-border m-5" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <P class="productValue">{{productDetailObject?.productLineId}}</P>
                                <p *ngIf="!loader" class="product">PRODUCT LINE</p>
                            </div>
                            <div class="col-12">
                                <p class="productValue">{{productDetailObject?.serialNumber}}</p>
                                <p *ngIf="!loader" class="product">SERIAL NO</p>
                            </div>
                            <div class="col-12">
                                <p class="productValue">{{productDetailObject?.cliTagNo}}</p>
                                <p *ngIf="!loader" class="product">CLIENT TAG NO</p>
                            </div>
                        </div>
                        <hr *ngIf="!loader" class="dashedLine">
                        <div class="row">
                            <div class="col-6" *ngFor="let product of productDetailObject?.prodAttributes">
                                <p class="productValue">{{product.attributeValue}}</p>
                                <p class="product">{{product.attributeId.attributeName}}</p>
                            </div>
                            
                        </div>
                        
                        <div *ngIf="!loader">
                            <hr class="dashedLine">
                            <div class="row">
                                <div class="col-12" style="font-weight: 500;">
                                    CONTACT DETAILS
                                </div>
                            </div>
                            <div class="row contactDetailsBlock">
                                <div class="product contactText col-5">
                                    Contact Name :
                                </div>
                                <div class="attributeValue col-7">
                                    <p>{{productDetailObject?.contName1}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="product contactText col-5">
                                    Phone Number :
                                </div>
                                <div class="attributeValue col-7">
                                    <p>{{productDetailObject?.contPhone1}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="product contactText col-5">
                                    Email Address/Link:
                                </div>
                                <div class="attributeValue col-7">
                                    <p *ngIf="validateEmail1 ; else elseBlock">{{productDetailObject?.contEmail1}}</p>  
                                    <ng-template #elseBlock>
                                        <a *ngIf="validateURL1" [routerLink]="" (click)="openLink(productDetailObject?.contEmail1)">Find a Local CIRCOR Representative</a>
                                        <p *ngIf="!validateURL1">{{productDetailObject?.contEmail1}}</p>
                                    </ng-template>   
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="product contactText col-5">
                                    Contact Name :
                                </div>
                                <div class="attributeValue col-7">
                                    <p>{{productDetailObject?.contName2}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="product contactText col-5">
                                    Phone Number :
                                </div>
                                <div class="attributeValue col-7">
                                    <p>{{productDetailObject?.contPhone2}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="product contactText col-5">
                                    Email Address/Link:
                                </div>
                                <div class="attributeValue col-7">
                                        <p *ngIf="validateEmail2; else elseblock2">{{productDetailObject?.contEmail2}}</p>  
                                        <ng-template  #elseblock2>
                                            <a *ngIf="validateURL2" [routerLink]="" (click)="openLink(productDetailObject?.contEmail2)">Find a Local CIRCOR Representative</a>
                                            <p *ngIf="!validateURL2">{{productDetailObject?.contEmail2}}</p>
                                        </ng-template> 
                                </div>
                            </div>
                        </div>

                        <div *ngIf="!loader">
                            <hr class="dashedLine"> 
                            <div>
                            <!-- <div *ngIf="isUserType()"> -->
                                <div *ngIf="isgetSpareParts && isUserType()">
                                    <div class="row">
                                <div class="col-12" style="font-weight: 500;">
                                    SPARE PARTS
                                </div>
                            </div>
                            <div>
                            <div class="d_flex mtp5">
                                <div class="d_flex sp_flex1">
                                <span class="width8p" *ngIf="CheckBoxVisibleForQuoteOrder() && this.sparePartsResult.length > 0" >
                                    <input type="checkbox" [(ngModel)]="isselectAll" (change)="selectAll(isselectAll)"/>
                                 </span>
                                <span class="width42p">Description </span>
                                <span class="width32p">Part No</span>
                                <span class="width18p">
                                   <span *ngIf="currency == 'EUR'">Price(&#8364;)</span>
                                   <span *ngIf="currency == 'USD'">Price(&#36;)</span>
                                </span>
                                <span *ngIf="isOrgPrice" class="width22p">
                                    <span *ngIf="currency == 'EUR'">MRRP(&#8364;)</span>
                                    <span *ngIf="currency == 'USD'">MRRP(&#36;)</span>
                                </span>
                                </div>

                                <div class="d_flex sp_flex" *ngFor="let spareParts of sparePartsResult"> 
                                    <span class="width8p" *ngIf="CheckBoxVisibleForQuoteOrder() && this.sparePartsResult.length > 0" >
                                        <input type="checkbox" (change)="checkForAllSelect()" [(ngModel)]="spareParts.checked" [disabled]="checkForDisable(spareParts.description)"/>
                                        <!-- <input type="checkbox" (change)="checkForAllSelect()" [(ngModel)]="spareParts.checked" /> -->
                                    </span>
                                    <span class="width42p" [innerHTML]="spareParts.description"></span>
                                    <span class="width32p" [innerHTML]="spareParts.partNo"> </span>
                                    <span class="width18p">
                                        <span *ngIf="currency == 'EUR'"> {{spareParts.discountPrice | numberFormatter}}</span>
                                        <span *ngIf="currency == 'USD'"> {{spareParts.discountPrice  | numberFormatter}}</span>
                                        <!-- <span *ngIf="currency === 'USD'"> {{spareParts.discountPrice  | number : '0.2-2'}}</span> -->

                                    </span>
                                    <span *ngIf="isOrgPrice" class="width22p">
                                        <span *ngIf="currency == 'EUR'"> {{spareParts.price | numberFormatter}}</span>
                                        <span *ngIf="currency == 'USD'"> {{spareParts.price | numberFormatter }}</span>
                                    </span>
                                </div>

                      
                                
                            </div>
                            <div class="mtp10">
                                <button class="quote" *ngIf="this.isCheckQuoteOrder && this.isCheckQuoteOrder.quoteAdmin" (click)="sparePartsQuotesAndOrder('quote')">Quote</button>
                                <button class="order" *ngIf="this.isCheckQuoteOrder && this.isCheckQuoteOrder.orderAdmin" (click)="sparePartsQuotesAndOrder('order')">Order</button>
                                <button class="sendothers" *ngIf="this.isCheckQuoteOrder && role === 'Admin'" (click)="sendOthers()">Send Others</button>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                            

                    </div>
                </div>

                <!-- <div class="card hlp_lght_hldr">
                    <a href="https://helplightning.net/help?enterprise_id=163915&group_id=182307&group_name=Support+center" target="_blank">
                    <img src="../../../assets/Help_Lightning_Logo.jpg"/>
                    <div class="hlp_light_txt">Remote Visual <br/>Assistance Service</div>
                </a>
                </div> -->
            </div>
            <div class="col-lg-8 right_cont" *ngIf="!unrestrictedUser">
                <div class="row">
                    <div class="col-10 pl-0">
                     <img *ngIf="isProductNotes" class="imgArrow" src="../../assets/arrow.png" (click)="gotoPreviousPage()">
                     <h4 [ngStyle]="{'margin-top':isProductNotes === true ? '-20px' : '40px' }" class="ProductNonetsTitle f-m-s-b">PRODUCT NOTES <span *ngIf="!isProductNotes">({{notesLength}})</span></h4>
                    </div>
                    <div class="row">
                        <div class="col-2">
                            <button *ngIf="!isProductNotes && !unrestrictedUser" class="addButton" (click)="openDialog()"> Add Note</button> 
                        </div>
                    </div>
                    <div *ngIf="isProductNotes" class=" pagination peginationStyle">
                        <input *ngIf="notesCount==1" type="image" class="previousArrow" (click)="previousNotes()" src="../../assets/arrow.png" disabled /> <span *ngIf="notesCount < 10" class ="countClassLessThanTen">{{notesCount}} </span> <span *ngIf="notesCount>9" class ="countClassGreaterThanNine"> {{notesCount}}</span><span class="slash">/</span> <span class ="">{{notesLength}} </span>
                        <input *ngIf="notesCount==notesLength" type="image" class="nextArrow" (click)="nextNotes()" src="../../assets/arrow_right.png" disabled />
                        <input *ngIf="notesCount!=1"type="image" class="previousArrow" (click)="previousNotes()" src="../../assets/arrow.png"/>
                        <input *ngIf="notesCount!=notesLength" type="image" class="nextArrow" (click)="nextNotes()" src="../../assets/arrow_right.png"/>
                    </div>
               </div>
                <div *ngIf="!isProductNotes && !isNotes" class="row">
                    <div class="col-3 mb-10" *ngFor = "let note of notes; let i = index">
                        <div class="card productNotesCard">
                            <div class="card-body" (click)="showProductNotes(note.productNotes.productNotesId, i)">
                                <h4 *ngIf="role === 'User'" class="card-title">User ID: {{note.productNotes.updatedBy.operatorId}} </h4>
                                <h4 *ngIf="role === 'Admin'" class="card-title">{{note.productNotes.updatedBy.firstName}} {{note.productNotes.updatedBy.lastName}}</h4>

                                <h4 class="dateAlignment">{{note.productNotes.updatedOn | date:'dd MMM yyyy'}}</h4>

                                <hr class="dashedLine">
                                <div class="card-text f-m-l-12" [innerHTML]="note.productNotes.productNotes"></div>
                                <img *ngIf = "role === 'Admin' || getNotesUpdatedUserOperatorId(note.productNotes.updatedBy.operatorId)" class="deleteButton" (click)="deleteNotesPopUp(note.productNotes.productNotesId)" src="../../../assets/delete.png"/>
                                <img *ngIf="note.productNotesFile.filepath != null || note.productNotesFile.length > 0" class="imageAllocation" src="../../../assets/attachment.png">
                                <span *ngIf="note.productNotesFile.filepath != null || note.productNotesFile.length > 0" class="badgeStyle" matBadge="{{note.productNotesFile.length}}" matBadgeOverlap="false" (click)="showProductNotes(note.productNotes.productNotesId, i)"></span>    
                            </div>
                        </div>
                    </div>
                </div>
                
                <div *ngIf="isProductNotes && !isNotes" class="row">
                    <div class="col-12">
                        <div class="card specificProductNotesCard">
                            <div class="card-body">
                                <div class="row">
                                <h4 *ngIf="role === 'User'" class="card-title col-8">User ID: {{specificNotesDetails[0].productNotes.updatedBy.operatorId}} </h4><br>
                                <h4 *ngIf="role === 'Admin'" class="card-title col-8">{{specificNotesDetails[0].productNotes.updatedBy.firstName}} {{specificNotesDetails[0].productNotes.updatedBy.lastName}}</h4><br>
                                  <h4 class="date col-2">{{specificNotesDetails[0].productNotes.updatedOn | date: 'dd MMM yyyy'}}</h4>
                                </div>
                                <!-- <div class="row">
                                    <div class="col-12">
                                        <h4 class="dateAlign">{{specificNotesDetails[0].productNotes.updatedOn | date:'dd MMM yyyy'}}</h4>
                                    </div>
                                </div> -->
                                <hr class="dashedLine">
                                <div class="f-m-l-12" [innerHTML]="specificNotesDetails[0].productNotes.productNotes"></div><br>  
                                <h5 *ngIf="specificNotesDetails[0].productNotesFile.length>=1" class="f-m-r">PHOTO / ATTACHMENT UPLOADS</h5>
                                <div class="row">
                                  <div class="col-2" *ngFor="let notesFile of specificNotesDetails[0].productNotesFile">
                                    <img *ngIf="notesFile.fileType != 'image/jpeg' && notesFile.fileType != 'image/png'" class="pdfImgMargin" src="../../../assets/file.png" (click)="downloadFile(notesFile.filepath)"><br>
                                    <img *ngIf="notesFile.fileType === 'image/jpeg' || notesFile.fileType === 'image/png'" height="40" width="40" src="{{notesFile.filepath}}" (click)="openPopup(notesFile.filepath)"><br>
                                  <div  class="datePostion">{{notesFile.fileName}}</div> <br> 
                                  </div>
                                </div>
                                <!-- <div class="row">
                                    <div class="col-2" *ngFor="let notesFile of specificNotesDetails[0].productNotesFile">
                                     {{specificNotesDetails[0].productNotes.updatedOn | date: 'dd MMM yyyy'}}
                                    </div>
                                </div> -->
                                <button class="backButton" (click)= "gotoPreviousPage()">BACK</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="isProductNotes && isNotes" class="row">
                    <div class="col-12">
                        <div class="card specificProductNotesCard">
                            <div class="card-body">
                                <div class="row">
                                    <h4 *ngIf="role === 'User'" class="card-title col-8">User ID: {{nextNote.productNotes.updatedBy.operatorId}} </h4><br>
                                    <h4 *ngIf="role === 'Admin'" class="card-title col-8">{{nextNote.productNotes.updatedBy.firstName}} {{nextNote.productNotes.updatedBy.lastName}}</h4><br>    
                                  <h4 class="date col-2">{{nextNote.productNotes.updatedOn | date: 'dd MMM yyyy'}}</h4>
                                </div>
                                <hr class="dashedLine">
                                <div class="f-m-l-12"[innerHTML]="nextNote.productNotes.productNotes"></div> <br>
                                <h5 *ngIf="nextNote.productNotesFile.length>=1" class="f-m-r">PHOTO / ATTACHMENT UPLOADS</h5>
                                <div class="row">
                                  <div class="col-2" *ngFor="let notesFile of nextNote.productNotesFile">
                                    <img *ngIf="notesFile.fileType != 'image/jpeg' && notesFile.fileType != 'image/png'" class="pdfImgMargin" src="../../../assets/file.png" (click)="downloadFile(notesFile.filepath)">
                                    <img *ngIf="notesFile.fileType === 'image/jpeg' || notesFile.fileType === 'image/png'" height="40" width="40" src="{{notesFile.filepath}}" (click)="openPopup(notesFile.filepath)"><br><br>
                                    <div  class="datePostion">{{notesFile.fileName}}</div> <br>    </div>
                                </div>
                                <!-- <div class="row">
                                    <div class="col-2" *ngFor="let notesFile of nextNote.productNotesFile">
                                     {{nextNote.productNotes.updatedOn | date: 'dd MMM yyyy'}}
                                    </div>
                                  </div> -->
                                <button class="backButton" (click)= "gotoPreviousPage()">Back</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
         
            <div class="overlay" *ngIf="postFormSubmit">
                <div *ngIf="dataSharingService.getShowSmallLoader()" id="loader1" class="d-flex justify-content-center align-items-center"><img src="assets/loader1.gif"/></div>

                <div class="overlay_hldr">
        
                    <span class="close_icon1" (click)="closeFormPopup();">Close X</span>
                    <form [formGroup]="sendUserForm" class="mtp20">
                        <div *ngIf="usPopupToggle">
                        <div class="form-grp user_status_popup">
                                <label>Email<span class="md_field">*</span></label>
                                <textarea formControlName="emailIds" class="email" cols="1" [ngClass]="{ 'is-invalid':  form.emailIds.errors }"></textarea>
                                <div class="invalid-feedback" *ngIf="form.emailIds.invalid && (form.emailIds.dirty || form.emailIds.touched)">Email id is required</div>
                        </div>
                        <div class="form-grp user_status_popup">
                                <label>Message<span class="md_field">*</span></label>
                                <textarea formControlName="message" [ngClass]="{ 'is-invalid':  form.message.errors }"></textarea>
                                <div class="invalid-feedback mtp_msg" *ngIf="form.message.invalid && (form.message.dirty || form.message.touched)">Message is required</div>
                        </div>
                    </div>
                    <span class="grid-btn">
                        <button *ngIf="usPopupToggle" class="btn ttu" (click)="sendOthersToSpareParts()" [disabled]="!sendUserForm.valid">Send</button>
                        <button class="btn ttu ml18" (click)="closeFormPopup()">Close</button>
                    </span>
                      </form>
                </div>
            </div>
        </div>
</div>