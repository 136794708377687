import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProductService } from '../services/product.service';
import { ProductDetails } from 'src/app/models/productDetails';
import { Router, NavigationExtras } from '@angular/router';
import { DataSharingService } from '../services/data-sharing.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  unrestrictedUserForm: FormGroup;
  loader:boolean = true;
  isMessage:boolean = false;
  isSerialNo:string;
  productDetails:ProductDetails[];
  productDetailObject= new ProductDetails();
  newProducts:any;

  submitted = false;
  loading = false;
  profile:any;
  isHeader:boolean=true;
  unrestrictedUser:boolean=false;
  constructor(private productService:ProductService, private route: Router, private dataSharingService:DataSharingService) {
    this.unrestrictedUserForm = new FormGroup({
      userName: new FormControl('', [Validators.required])
    });
   }

  ngOnInit(): void {
    this.dataSharingService.setHeader(this.isHeader);
  }

  submit(serialNo){
    this.productService.fetchProductDetails(serialNo).subscribe(data=>{
      this.isSerialNo = serialNo;
      this.loader=false;
      this.productDetails = data;
       this.newProducts = [];
       this.newProducts = this.productDetails.filter((data) => 
       (data.serialNumber == serialNo))
        console.log("newProduct",this.newProducts);
        if(Object.keys(this.newProducts).length>0){
          this.isMessage = false;
          this.unrestrictedUser = true;
          this.dataSharingService.setUnrestrictedUser(this.unrestrictedUser);
          this.productDetailObject = this.newProducts[0];
          console.log("productObject",this.productDetailObject);
          let navigationExtras: NavigationExtras = {
            queryParams: {
                "productId": serialNo,
                // "unrestrictedUser":this.unrestrictedUser,
            }
        };
          this.route.navigate(['/product-details'], navigationExtras);
        }
      
       else{
        this.isMessage=true;
       }
      // });
       

      }),error => {
        console.log(error);
      }
  }

}
