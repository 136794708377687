import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../services/data-sharing.service';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  isHeader: boolean;
  unrestrictedUser: boolean;

  constructor(private dataSharingService: DataSharingService) { 
    if (localStorage.getItem("accessToken")) {
      this.dataSharingService.setUnrestrictedUser(false);
      this.unrestrictedUser = false;
    }
  }

  ngOnInit(): void {
    this.dataSharingService.isHeaderVisibility.subscribe(header =>{
      this.isHeader = header; 
    })
  }

}
