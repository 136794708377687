import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { Router, NavigationExtras } from '@angular/router';
import{ environment} from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  accessToken:any;
  constructor(private route: Router,private _http: HttpClient,private authService: MsalService,private broadcastService: BroadcastService) { }

  private _fileUpload: string;

fileUpload(fileList:any){
  this._fileUpload=`${environment.apiUrl}fileup/fileUploadTag`;
  let formData: FormData = new FormData();
  console.log("came here too"+this._fileUpload);
  let file: File =fileList._files[0];
  formData.append("multiple", file);
  return this._http.post(this._fileUpload, formData);
 }
 fileUploadProduct(fileList:any,prodLine:string,dataCategory:string){
  this._fileUpload=`${environment.apiUrl}fileup/fileUpload`;
  let formData: FormData = new FormData();
  console.log("came here too"+this._fileUpload);
  let file: File =fileList._files[0];
  formData.append("multiple", file);
  formData.append("productLine", prodLine);
  formData.append("documentCategory", dataCategory);
  return this._http.post(this._fileUpload, formData);
  }
  
  fileUploadSpareParts(fileList:any,prodLine:string,dataCategory:string) {
  this._fileUpload=`${environment.apiUrl}fileUploadSpareParts`;
  let formData: FormData = new FormData();
  console.log("came here too"+this._fileUpload);
  let file: File =fileList._files[0];
  formData.append("file", file);
  formData.append("productLine", prodLine);
  formData.append("documentCategory", dataCategory);
  return this._http.post(this._fileUpload, formData);
  }

 fileUploadForNotes(fileList:any,prodLine:string,productSerialNo:string):Observable<any>{​​​​​
  this._fileUpload=`${environment.apiUrl}notes/notesFileUpload`;
  let formData: FormData = new FormData();
  console.log("came here too"+this._fileUpload);
  for (let i=0; i < fileList._files.length; i++){
  formData.append("notesFiles", fileList._files[i]);
  }
  formData.append("productLineDetails", prodLine);
  formData.append("productSerialNo", productSerialNo);

  return this._http.post(this._fileUpload, formData);
  }​​​​​

  sprefDocUpload(fileList:any, productLine:string):Observable<any>{​​​​​
    this._fileUpload=`${environment.apiUrl}uploadsprefdocs`;
    let formData: FormData = new FormData();
    for (let i=0; i < fileList._files.length; i++){
    formData.append("files", fileList._files[i]);
    }
    formData.append("productLine", productLine);
    return this._http.post(this._fileUpload, formData);
  }​​​​​



}
