import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {

  /**
   * Name: Sandhya Misal
   * Funstinality: Entered keyword will be highlighted.
   * Code Disc: Entered keyword will be highlighted in question and answer section.
   **/
  transform(text: any, search:any): any {
    var srchTxt = search+'';
    if(srchTxt.length > 2){
      return search ? text.replace(new RegExp(search, 'gi'), `<span class="highlight">${search}</span>`) : text;
    } else {
      return text;
    }    
  }

}
