export const OAuthSettings = {
//         clientId: '0e307113-75d1-43dd-acd8-bc5ec55a0af4', // This is your client ID
//         authority: 'https://login.microsoftonline.com/5f681148-fa85-420d-8673-08b4e9c4266f/', // This is your tenant ID
//        redirectUri: 'https://dev-traceability.azurewebsites.net/auth',// This is your redirect URI
//         clientId: '0e307113-75d1-43dd-acd8-bc5ec55a0af4', // This is your client ID
//        authority: 'https://login.microsoftonline.com/5f681148-fa85-420d-8673-08b4e9c4266f/', // This is your tenant ID
//       redirectUri: 'https://test-traceability.azurewebsites.net/auth',// This is your redirect URI
        clientId: '73866854-7051-487d-bcdd-9398fd283385', // This is your client ID
        authority: 'https://login.microsoftonline.com/5f681148-fa85-420d-8673-08b4e9c4266f/', // This is your tenant ID
        redirectUri: 'https://circorsmart.azurewebsites.net/auth',// This is your redirect URI
    scopes: [
        
            'user.read',
            'openid',
            'profile',
    ]
  };