import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { MsalService } from "@azure/msal-angular";
import { EMPTY, from, Observable, of } from "rxjs";
import { catchError, concatMap, map } from "rxjs/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private route: Router) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers.has('X-Skip-Auth')) {
        const headers = request.headers.delete('X-Skip-Auth');
        return next.handle(request.clone({ headers }));
    }
    const itemStr = localStorage.getItem("accessToken");
    if (!itemStr) {
        this.route.navigate(["/login"]);
        return EMPTY;
    }
    const item = JSON.parse(itemStr);
    request = request.clone({
      setHeaders: {
        authorization: `Bearer ${item.value}`
      }
    });
    return next.handle(request);
    }

}

