import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
//import { Router } from '@angular/router';
import { Router } from '@angular/router';
import {DataManager} from '@syncfusion/ej2-data';
import { GridComponent, RowSelectEventArgs, FilterType, EditSettingsModel, selectVirtualRow, Column, IRow, rowSelected, SaveEventArgs, ToolbarItems, CommandModel, CommandClickEventArgs, click, CommandButtonOptions,IEditCell} from '@syncfusion/ej2-angular-grids';
import { FetchUserDetailsService } from '../services/fetch-user-details.service';
import { UserDetails } from '../models/userDetails';
import { DataSharingService } from '../services/data-sharing.service';
import { ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DropDownList, Fields } from '@syncfusion/ej2-angular-dropdowns';
import { query } from '@angular/animations';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  loading = false;
  submitted = false;
  public data: Object[];
  dynamicUserRows:UserDetails[]=[];
  addedUser:UserDetails;
  removedUser:UserDetails;
  public filter: Object;
  //public toolbar: Object[];
  public toolbar: ToolbarItems[];
  loader:boolean;
  userRows:UserDetails[]=[];
  public operatorEditData: object;
  row:string;
  isSelected:boolean=false;
  @ViewChild('grid')
  public grid: GridComponent;
  public editSettings: EditSettingsModel;
  public commands: CommandModel[];
  selectBrand:any;
  brandArr:any;
  newArr:String[]=[];
  public brandParam:IEditCell;
  public elem: HTMLElement; 
  public Obj:DropDownList;

  

  constructor(private formBuilder: FormBuilder, private route: Router, private userOnBehalfService:FetchUserDetailsService, 
    private dataSharingService:DataSharingService, private snackBar: MatSnackBar) { 
       this.registerForm= new FormGroup({
           firstName:new FormControl('', [Validators.required]),
           email:new FormControl('', [Validators.required,
            Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]),
            mobileNo:new FormControl('', [
           // Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
            Validators.pattern("^[0-9]*$"),
            Validators.minLength(1), Validators.maxLength(100)]),
            location: new FormControl(),
          jobTitle: new FormControl(),
          department:new FormControl(),
          orgName: new FormControl('',[Validators.required]),
    lastName:new FormControl('', [Validators.required]), 
    brand: new FormControl('',[Validators.required])

       });
  }

  ngOnInit() {
     this.loader=false;
     this.filter = { type: "CheckBox" };
     this.toolbar = ['Search','Edit', 'Delete'];
     this.data = this.dynamicUserRows;
     this.editSettings = { showConfirmDialog: true, showDeleteConfirmDialog: true, 
                          allowEditing: true,allowEditOnDblClick:false, allowDeleting: true, mode: 'Normal'};
     this.commands = [{ type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
        { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons', click:()=>this.deleteUserOnBehalf(this.dynamicUserRows)}},
        { type: 'Save', buttonOption: { cssClass: 'e-flat', iconCss: 'e-update e-icons' } },
        { type: 'Cancel', buttonOption: { cssClass: 'e-flat', iconCss: 'e-cancel-icon e-icons' } }];
    this.fetchBrandDetails();
    this.dataParams();
    
  }

  get f() { return this.registerForm.controls; }

  onSubmit() {
      this.submitted = true;
      this.loader=false
      if (this.registerForm.invalid) {
        return;
      }
    
      this.addedUser = this.registerForm.value;
      this.dynamicUserRows.push(this.addedUser); 
     
      this.grid.refresh(); 
      this.registerForm.reset();
  }

  addUserOnBehalf(){

    console.log("Inside add User on Behalf");
      this.submitted = true;
      this.loader=false
      if (this.registerForm.invalid) {
        return;
      }
    
      this.addedUser = this.registerForm.value;
      this.addedUser.approvalStatus ="Approved";
      //this.addedUser.operatorId=11;
      console.log(this.addedUser);
      this.userOnBehalfService.addUserOnBehalf(this.addedUser).subscribe(data =>{
      console.log(data);
      console.log(data.saved_data);
      //this.data=data;
      this.addedUser=data.saved_data;
      console.log(this.addedUser);
      this.dynamicUserRows.push(this.addedUser); 
      this.snackBar.open('User has been successfully registered','', {
        duration: 3000,
        // panelClass: 'green-snackbar'
      })
      this.submitted = false;
      this.grid.refresh(); 
      this.registerForm.reset();
    }, (err) => {
        console.log(err);
        this.snackBar.open(err.error.details[0],'', {
          duration: 3000,
        })
      })
  }

  onReset() {
    this.submitted = false;
    this.registerForm.reset();
  }
  
  ShowDashboardPage(){
    this.route.navigate(['/product-search']);
  }

  rowSelected(args: RowSelectEventArgs) { // Get the selected row indexes.
    const selectedrecords: object = this.grid.getSelectedRecords();
    console.log("selected Records in row selected method:",selectedrecords);
  }

  editUserOnBehalf(dynamicUserRows){
    const selectedrecords: object = this.grid.getSelectedRecords();
    console.log("records:",selectedrecords);
      if(Object.keys(selectedrecords).length>=1){
        this.userRows = [];        
        for (let i = 0; i < Object.keys(selectedrecords).length; i++) {
          selectedrecords[i].userBrand[0].userBrand=selectedrecords[i].brand;
          this.userRows.push(selectedrecords[i]);
        }
        
        this.userOnBehalfService.updateUserOnBehalf(this.userRows).subscribe(data=>
          {
            console.log("calling update method",data);
            this.row = data.Message;
            if(this.row=='Success'){
              this.snackBar.open('Data has been updated','', {
              duration: 3000,
        })
        this.dataSharingService.setGrid(true);
       }
          }, (err) => {
            console.log(err);
          })
    }
      else{
          this.snackBar.open('Row should be selected','', {
            duration: 3000,
          })
        }
  }

  deleteUserOnBehalf(dynamicUserRows){

    console.log("Inside delete User on Behalf");
      this.submitted = true;
      this.loader=false;
      const selectedrecords: object = this.grid.getSelectedRecords();
      console.log("selectedrecords",selectedrecords);
      
      if(Object.keys(selectedrecords).length==1){
          this.removedUser = selectedrecords[0];
          console.log("removedUser",this.removedUser);
          
          this.userOnBehalfService.deleteUserOnBehalf(selectedrecords[0]).subscribe(data =>{
          console.log(data);
          if(this.row=='Success'){
            this.snackBar.open('Data has been deleted','', {
            duration: 3000,
          })
         // this.dataSharingService.setGrid(true);
          }
         }, (err) => {
            console.log(err);
         })
      }else{
        this.snackBar.open('Row should be selected','', {
          duration: 3000,
        })
      }
  }

  validateNumber(event) {
    const keyCode = event.keyCode;

    const excludedKeys = [8, 37, 39, 46];

    if (!((keyCode >= 48 && keyCode <= 57) ||
      (keyCode >= 96 && keyCode <= 105) ||
      (excludedKeys.includes(keyCode)))) {
      event.preventDefault();
    }
  }
  
  fetchBrandDetails(){
    this.userOnBehalfService.fetchBrandDetails().subscribe(data => {
    this.selectBrand = data.filter(a => a);
    this.brandArr = Object.keys(this.selectBrand).map(key =>({ id:key ,value: this.selectBrand[key]}));
    console.log(this.brandArr);
    this.newArr=this.brandArr.map(a => a.value);
    console.log(this.newArr);
    }, err =>{
      console.log(err);
    })
  }
  
  dataParams(){
    this.brandParam={
      create:() =>{
        this.elem=document.createElement('input');
        return this.elem;
      },
      read:() =>{
        return this.Obj.text;
      },
      destroy:() =>{
        this.Obj.destroy();
      },
      write:() => {
        this.Obj=new DropDownList({
          dataSource:new DataManager(this.brandArr),
          fields:{text:'value', value:'value'},

        });
        this.Obj.appendTo(this.elem);
      }
    };
  }

}
