import { NgModule } from '@angular/core';
import { BackButtonDirective } from './directives/go-back-directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { InfiniteScrollModule } from "ngx-infinite-scroll";


@NgModule({
  declarations: [BackButtonDirective],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    InfiniteScrollModule
  ],
  exports: [
    BackButtonDirective,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    InfiniteScrollModule
  ]
})
export class SharedModule { }
