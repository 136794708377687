import { ChangeDetectorRef, Component,OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { DataSharingService } from './services/data-sharing.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'circor-admin-ui';
  subscription: any;
  showLoader: boolean;
  DelAcct: boolean = false;

  constructor(private broadcastService: BroadcastService, public dataSharingService: DataSharingService, private cd: ChangeDetectorRef, private router: Router) {
    const itemStr = localStorage.getItem("accessToken");
    if (itemStr && !this.dataSharingService.refreshTokenTimer) {
      this.dataSharingService.refreshAccessToken();
    }
  }
  ngOnInit(): void {
    this.getLoaderStatus();
    //localStorage.clear();
  
    this.subscription=  this.broadcastService.subscribe("msal:acquireTokenFailure", (payload) => {
    });
 // this.broadcastService.subscribe("msal:loginSuccess", payload => {
    // do something here
    
//});
/*this.authService.handleRedirectCallback((authError, response) => {
  if (authError) {
    console.error('Redirect Error: ', authError.errorMessage);
    return;
  }
  console.log('Redirect Success: '+ response);
  this.router.navigate(['/product-search']);
});*/

window.addEventListener( "pageshow", function ( event ) {
  var historyTraversal = event.persisted || 
                         ( typeof window.performance != "undefined" && 
                              window.performance.navigation.type === 2 );
  if ( historyTraversal ) {
    // Handle page restore.
    window.location.reload();
  }
});
  }

   getLoaderStatus() {
    this.dataSharingService.fetchLoader.subscribe((val => {
      this.showLoader = val;
      this.cd.detectChanges();
    }))
   }
  
  get getLoaderStatus$() {
    return this.dataSharingService.fetchLoader;
   }
  
   goBack() {
    this.router.navigate(['/login'])
   }
  
    openDelAcctPopUp() {
      this.DelAcct = true;
    }
  
    delAcct() {
     
   }

}
