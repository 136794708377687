
<div class="faqPage">
    <div class="row breadcrumb-san">
    <div *ngIf = "!unrestrictedUser" class="wd99p">
        <div class="row dis-hldr">
       
<div class="breadcrumb-sa">
<span class="breadcrumb-link" (click)="ShowDashboardPage()"> Dashboard - </span><span class="headerTextAllignment">FAQ</span></div>
<div class="d-flex sp-even">  
    <div class="d-flex">
<button class="productNotesButton" (click)="ShowProductDetailPage()">
    <img src="../../assets/note_16w.png"> Product Notes</button>
    </div>
    <div class="d-flex">
    <button class="instructionMannualButton" (click)="ShowInstructionalManualPage()"><img src="../../../assets/manual.png"> Documents and Links</button>
</div>
</div>
</div>
</div>


<div *ngIf = "unrestrictedUser" class="wd99p">
<div class="row dis-hldr">
    <div class="breadcrumb-sa"> <span class="breadcrumb-text">FAQ</span></div>
    <div class="d-flex sp-even">  
        <div class="d-flex">
        <button class="productNotesButton" (click)="ShowProductDetailPage()">
            <img src="../../assets/note_16w.png"> Product Notes</button></div>
            <div class="d-flex">
            <button class="instructionMannualButton" (click)="ShowInstructionalManualPage()">
                <img src="../../../assets/manual.png"> Documents and Links</button></div>
                </div>
        </div>
        </div>
        </div>

        <div class="row mainPage">
            <div class="col-lg-4">
                <div class="card productDetails">
                    <div class="card-body">
                        <h4 class="productDetailsContainerText">PRODUCT DETAILS</h4>
                        <div class="text-center">
                            <div *ngIf="loader" class="spinner-border m-5" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <P class="productValue">{{productDetailObject.productLineId}}</P>
                                <p *ngIf="!loader" class="product">PRODUCT LINE</p>
                            </div>
                            <div class="col-12">
                                <p class="productValue">{{productDetailObject.serialNumber}}</p>
                                <p *ngIf="!loader" class="product">SERIAL NO</p>
                            </div>
                            <div class="col-12">
                                <p class="productValue">{{productDetailObject.cliTagNo}}</p>
                                <p *ngIf="!loader" class="product">CLIENT TAG NO</p>
                            </div>
                        </div>
                        <hr *ngIf="!loader" class="dashedLine">
                        <div class="row">
                            <div class="col-6" *ngFor="let product of productDetailObject.prodAttributes">
                                <p class="productValue">{{product.attributeValue}}</p>
                                <p class="product">{{product.attributeId.attributeName}}</p>
                            </div>  
                        </div>
                        <div *ngIf="!loader">
                            <hr class="dashedLine">
                            <div class="row">
                                <div class="col-12" style="font-weight: 500;">
                                    CONTACT DETAILS
                                </div>
                            </div>
                            <div class="row contactDetailsBlock">
                                <div class="product contactText col-5">
                                    Contact Name :
                                </div>
                                <div class="attributeValue col-7">
                                    <p>{{productDetailObject.contName1}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="product contactText col-5">
                                    Phone Number :
                                </div>
                                <div class="attributeValue col-7">
                                    <p>{{productDetailObject.contPhone1}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="product contactText col-5">
                                    Email Address/Link:
                                </div>
                                <div class="attributeValue col-7">
                                    <p>{{productDetailObject.contEmail1}}</p>  
                                    
                                </div>
                            </div>
                            <br>
                            <div class="row">
                                <div class="product contactText col-5">
                                    Contact Name :
                                </div>
                                <div class="attributeValue col-7">
                                    <p>{{productDetailObject.contName2}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="product contactText col-5">
                                    Phone Number :
                                </div>
                                <div class="attributeValue col-7">
                                    <p>{{productDetailObject.contPhone2}}</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="product contactText col-5">
                                    Email Address/Link:
                                </div>
                                <div class="attributeValue col-7">
                                        <p>{{productDetailObject.contEmail2}}</p>  
                                       
                                </div>
                            </div>
                        </div>

                        <div *ngIf="!loader">
                            <hr class="dashedLine">
                                <div *ngIf="isgetSpareParts && isUserType()">
                                    <div class="row">
                                <div class="col-12" style="font-weight: 500;">
                                    SPARE PARTS
                                </div>
                            </div>
                            <div>
                            <div class="d_flex mtp5">
                                <div class="d_flex sp_flex1">
                                <span class="width8p" *ngIf="CheckBoxVisibleForQuoteOrder() && this.sparePartsResult.length > 0" ><input type="checkbox" [(ngModel)]="isselectAll" (change)="selectAll(isselectAll)"/></span>
                                <span class="width32p">Description </span>
                                <span class="width22p">Part No</span>
                                <span class="width18p">Price</span>
                                <span class="width20p">Currency</span>
                                <span *ngIf="isOrgPrice" class="width22p">MRRP</span>
                                </div>

                                <div class="d_flex sp_flex" *ngFor="let spareParts of sparePartsResult"> 
                                    <span class="width8p" *ngIf="CheckBoxVisibleForQuoteOrder() && this.sparePartsResult.length > 0" ><input type="checkbox" (change)="checkForAllSelect()" [(ngModel)]="spareParts.checked"/></span>
                                    <span class="width32p">{{spareParts.description}}</span>
                                    <span class="width22p">{{spareParts.partNo}}</span>
                                    <span class="width18p">{{spareParts.discountPrice | numberFormatter}}</span>
                                    <span class="width20p">{{spareParts.currency}}</span>
                                    <span *ngIf="isOrgPrice" class="width22p">{{spareParts.price | numberFormatter}}</span>
                                </div>
                                
                            </div>
                            <div class="mtp10">
                                <button class="quote" *ngIf="this.isCheckQuoteOrder && this.isCheckQuoteOrder.quoteAdmin" (click)="sparePartsQuotesAndOrder('quote')">Quote</button>
                                <button class="order" *ngIf="this.isCheckQuoteOrder && this.isCheckQuoteOrder.orderAdmin" (click)="sparePartsQuotesAndOrder('order')">Order</button>
                                <button class="sendothers" *ngIf="this.isCheckQuoteOrder && role === 'Admin'" (click)="sendOthers()">Send Others</button>
                            </div>
                        </div>
                        </div>
                         
                            </div>

                        </div>
                </div>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-12 faqBlock">
                <!-- <div class="col-lg-12"> -->
              <div class="row">
                <div class="col-lg-6">
                   <img class="imgArrow" src="../../assets/arrow.png" (click)="gotoProductDetails()">
                   <h4 class="FAQTitle">FREQUENTLY ASKED QUESTIONS (FAQs)</h4>
                </div>
                <!-- <form [formGroup]="searchFaq"> -->
                    <div class="col-lg-5">
                        <input class="form-control border-right-0 border f-m-l-14" class="inputSearch" type="search" placeholder="Type words to find answer...." [(ngModel)]="searchword">
                    <!-- <div class="input-group m-b-5 productSearchBlock">
                        <input class="form-control border-right-0 border f-m-l-14" id="inputSearch" type="search" placeholder="Type words to find answer...." [(ngModel)]="searchword">
                        <span class="input-group-append">
                          <button class="btn btn-outline-secondary border-left-0 border" id="productSearch" type="button">
                            <img class="searchImg" src="../../../assets/search.png">
                          </button>
                        </span>
                    </div> -->
                </div>
                </div>
            <!-- </form> -->
                <!-- <div class="col-lg-12"> -->
                <mat-accordion>
                    <mat-expansion-panel *ngFor="let faq of faqData | filter:searchword" (opened)="panelOpenState = true"
                                         (closed)="panelOpenState = false">
                      <mat-expansion-panel-header>
                        <mat-panel-title>
                           <div innerHTML="{{faq.question | highlight:searchword}}"></div>
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <hr class="dashedHrLine">
                      <div class="row">
                      <div class="col-lg-2">
                          <div class="row">
                            <div class="col-6" *ngFor = "let image of faq.images">
                                <img src="{{image.fileName}}" height="60" width="60" (click)="openDialog(image.fileName)"/>
                            </div>
                          </div>
                      </div>
                      <div class="col-lg-10">
                        <div innerHTML="{{faq.answer | highlight:searchword}}"></div>
                      </div>
                    <!-- </div> -->
                </div>
                    </mat-expansion-panel>
                  </mat-accordion>
            <!-- </div> -->
        </div>
        </div>
      </div>
   

