import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { IspareParts, ProductDetails } from 'src/app/models/productDetails';
import { ProductService } from '../services/product.service';
import {Location} from '@angular/common';
import { FetchUserDetailsService } from '../services/fetch-user-details.service';
import { DataSharingService } from '../services/data-sharing.service';
import { FaqService } from '../services/faq.service';
import {faq} from '../models/faq'
import { MatSnackBar } from '@angular/material/snack-bar';
import{ environment} from '../../environments/environment'
import jwt_decode from "jwt-decode";

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
declare var angular: any;

export interface DialogData {
  image: string;
}
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {
  [x: string]: any;
  panelOpenState = false;
  public productId: string;
  docLocation: any;
  notes: any;
  notesLength: number;
  notesFileCount: number;
  productDetails:any;//ProductDetails[];
  productDetailObject= new ProductDetails();
  newProducts:any;
  loader:boolean=true;
  productLineId:any;
  faqData:faq[];
  getFaq:any;
  getImages:any;
  angular: any;
  faqImages:any;
  imageFileName:any;
  unrestrictedUser:boolean=true;
  content:any;
  public searchword : any;
  str: any;
  productFirstAttributes: string;
  imagePath:string;
  azureBlobImage: any;
  accessMessage:boolean;
  sparePartsResult: any;
  isgetSpareParts: boolean = false;
  sparePartsData: IspareParts[] = [];
  isselectAll: boolean = false;
  isCheckQuoteOrder: any;
  isOrgPrice: boolean = false;
  addNotes = {
    productNotes: {
      productSerialNo: null,
      productNotes: null,
      imageLoc: null,
      updatedBy: { operatorId: 0 },
      updatedOn: null,
      isDeleted: null,
      deletedBy: null,
      quoteText: null
    },
    productNotesFile: [{
      filepath: null,
      fileType: null,
    }]
  };
  constructor(private route: Router, private routes: ActivatedRoute, private productService:ProductService, private _location: Location,
    private fetchUserDetailsService:FetchUserDetailsService, private snackBar: MatSnackBar, private dataSharingService : DataSharingService, private faqService:FaqService,
    public dialog: MatDialog) { 
      this.routes.queryParams.subscribe((params) => {
        if (params["productId"] != null) {
          this.productId = params["productId"];
          sessionStorage.setItem('productId', this.productId);
        }
      });
    this._location.replaceState(this._location.path().split("?")[0], "");
    this.dataSharingService.setHeader(false);

  this.dataSharingService.unrestrictedUserAccess.subscribe(data =>
    this.unrestrictedUser=data);
    console.log("unrestrictedUser", this.unrestrictedUser);
    
    if (localStorage.getItem("accessToken")) {
      this.dataSharingService.setUnrestrictedUser(false);
      this.unrestrictedUser = false;
    }
    let decodedToken = jwt_decode(localStorage.getItem("accessToken"));
    this.email = decodedToken['unique_name'].includes('#') ? decodedToken['unique_name'].split("#").pop() : decodedToken['unique_name'];
    this.fetchUserDetailsService.fetchUseryEmail(this.email).subscribe(data => {
      this.dataSharingService.setUserTypeId(data?.userTypeId?.userTypeId);
      this.userOperatorId = data.operatorId;
      console.log("uTypeid", this.dataSharingService.getUserTypeId());
    });
    this.dataSharingService.fetchUserRole.subscribe((res: string) => {
      this.role = res;
    })
  }

  
//test
  // AZURE_BLOB_END_POINT = 'https://circortesttraceabilitysa.blob.core.windows.net/traceability/'
  // QUESTION_MARK = '?'
  // AZURE_BLOB_SAS_KEY = 'sv=2019-12-12&ss=b&srt=sco&sp=rwdlacx&se=2021-05-31T13:02:14Z&st=2020-11-06T05:02:14Z&spr=https&sig=VpbVv3y0ct%2Bm9jmRz%2FKTPsZ52OpujKieBGhQSdg3rJg%3D'
  //prod
  AZURE_BLOB_END_POINT = 'https://prodcircorsmartsa.blob.core.windows.net/traceability/'    
  QUESTION_MARK = '?'
  AZURE_BLOB_SAS_KEY = 'sv=2019-12-12&ss=bfqt&srt=sco&sp=rwdlacupx&se=2025-12-31T22:31:05Z&st=2021-01-05T14:31:05Z&spr=https&sig=yGEgOLDyUbQLAYoOqPp%2FOsfdiWZFHho57UQ0QdLkxFA%3D'
 
  // azureBlobImagePath = this.AZURE_BLOB_END_POINT + this.EncodedImageURL + this.QUESTION_MARK + this.AZURE_BLOB_SAS_KEY

  /**
   * Name : Sandhya Misal
   * Functionality :Calling fetchProductDetails method 
   * Code Desc :Calling fetchProductDetails method on page load
   **/
  ngOnInit(): void {
    this.productId = this.productId ?? sessionStorage.getItem('productId');
    this.fetchProductDetails();
    // this.azureBlobImagePath;
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('productId');
  }

  /**
   * Name : Sandhya Misal
   * Functionality :Calling api's to show product details and faq's
   * Code Desc :Fetching all the product details and faq related data through backend api.
   **/
  fetchProductDetails(){
    console.log("in fetchMethod", this.productId);
    if (this.productId) {
      this.productService.fetchProductDetails(this.productId).subscribe(data => {
        console.log("data", data);
        this.loader = false;
        this.productDetails = data;
        this.newProducts = [];
        this.newProducts = this.productDetails.filter((data) =>
          data.serialNumber == this.productId);
        console.log("newProduct", this.newProducts);
       
        this.productDetailObject = this.newProducts[0];
        this.docLocation = this.productDetailObject.docLocation;
        this.productFirstAttributes = this.productDetailObject.prodAttributes[0].attributeValue;
        console.log("productObject", this.productDetailObject);
        this.dataSharingService.setFaq(this.productDetailObject.productLineId);
        this.productLineId = this.productDetailObject.productLineId;
        this.checkQuoteAndOrderForProd();

        this.faqService.getFaq(this.productLineId).subscribe(faq => {
          this.faqData = faq;
          console.log("getFaq:", this.faqData);
          this.getFaq = [];
          this.faqImages = [];
          for (var obj of this.faqData) {
            this.getImages = [];
            obj.answer = this.transformLink(obj.answer);
            for (var img of obj.images) {
              console.log("img.fileName", img.fileName);
              img.fileName = this.AZURE_BLOB_END_POINT + img.fileName + this.QUESTION_MARK + this.AZURE_BLOB_SAS_KEY
              console.log("this.azureBlobImage", this.azureBlobImage);
            }
          }
          console.log("images", this.getFaq);
          //  for(var img of this.getFaq){
          //   console.log("faqImages",img);
          // this.faqImages.push(img.fileName);
          //  }
          //  console.log("faqImages",this.faqImages);
        }), error => {
          console.log(error);
        }

      }), error => {
        console.log(error);
      }
    
      this.dataSharingService.fetchNotes.subscribe((data) => {
        if (data == true) {
          this.fetchProductNotes();
        }
      });
      this.fetchProductNotes();
      this.fetchSpareParts(this.productId);
    
      // this.dataSharingService.faqs.subscribe(data =>{
      //   console.log("faqdata:",data);
      //   this.productLineId=data;
      //   console.log("LineId", this.productLineId);
        
      // })
      // console.log("productLineId in ngOnit:",this.productLineId);
      // this.fetchUserDetailsService.getFaq(this.productLineId).subscribe(faq =>{
      //   console.log("faq:",faq);
      // })
    }
  }
  /**
   * Name : Sandhya Misal
   * Functionality :Navigate to miscellaneous  information page
   * Code Desc :Navigate to miscellaneous  information page
   **/
  ShowInstructionalManualPage(){
    let navigationExtras: NavigationExtras = {
      queryParams: {
          productId: this.productId
      }
  };
    this.route.navigate(['/instructional-manual'], navigationExtras);
  }

  fetchSpareParts(productId: any) {
    this.productService.fetchSpareParts(productId).subscribe((res) => {
      console.log("sparts", res);
      this.sparePartsResult = res;
      if (res?.some(r => r.price > 0)) {
        this.isOrgPrice = true;
      }
      if (res.length > 0 ) {
        this.isgetSpareParts = true;
      }
    });
  }

  fetchProductNotes() {
    this.productService
    .fetchProductNotes(this.productId)
    .subscribe((notes) => {
      this.notes = notes;
      this.convertNotes();
      console.log("notes:", this.notes);
      this.notesLength = Object.keys(this.notes).length;
      console.log("notes length:", Object.keys(this.notes).length);
      // console.log("productNotesFile length:",Object.keys(this.notes.productNotesFile).length);
      for (var notesFile of this.notes) {
        this.notesFileCount = Object.keys(
          notesFile.productNotesFile
        ).length;
      }
    }),
    (error) => {
      console.log(error);
    };
  }

  isUserType() {
    console.log("uType", this.dataSharingService.getUserTypeId());
    return this.dataSharingService.getUserTypeId();
  }
  

  checkQuoteAndOrderForProd() {
    this.productService.checkQuoteandOrder(this.productLineId).subscribe((res) => {
        this.isCheckQuoteOrder = res;
        console.log("quoteorder", this.isCheckQuoteOrder);
      });
}

CheckBoxVisibleForQuoteOrder() {
  return (this.isCheckQuoteOrder && (this.isCheckQuoteOrder.quoteAdmin || this.isCheckQuoteOrder.orderAdmin));
}

selectAll(isSelectAll) {
  console.log("spresult", this.sparePartsResult, isSelectAll);
  this.sparePartsResult = this.sparePartsResult.map((sp: any) => {
    sp.checked = isSelectAll;
    return sp;
  });
}
checkForAllSelect() {
  let selectedSP = this.sparePartsResult.filter((sp: any) => {
    return sp.checked === true;
  });
  if (selectedSP.length === this.sparePartsResult.length) {
    this.isselectAll = true;
  } else if (selectedSP.length > 0) {
    this.isselectAll = false;
  }
}
resetSelection() {
  this.sparePartsResult = this.sparePartsResult.map((sp: any) => {
    sp.checked = false;
    return sp;
  });
  this.isselectAll = false;
}

convertNotes() {
  let quoteNo = 0;
  let orderNo = 0;
  for (let i = 0; i < this.notes.length; i++){
    let note = this.notes[i];
    let noteText = note.productNotes.productNotes;
    if (noteText.startsWith("Quote: quoteNo")) {
      noteText = noteText.replace("quoteNo", (quoteNo + 1) + "</span>");
      noteText = "<div class='table_notes'><span class='fb'>" + noteText + "</div>";
      quoteNo = quoteNo + 1;
      this.notes[i].productNotes.productNotes = noteText;
    } else if (noteText.startsWith("Order: orderNo")) {
      noteText = noteText.replace("orderNo", (orderNo + 1) + "</span>");
      noteText = "<div class='table_notes'><span class='fb'>" + noteText + "</div>";
      orderNo = orderNo + 1;
      this.notes[i].productNotes.productNotes = noteText;
    }
  }
}

sparePartsQuotesAndOrder(quoteOrOrder: string) {
  this.dataSharingService.setLoader(true);
  let postData = this.sparePartsResult.filter((sp: any) => sp.checked);
  console.log(postData);
  let notesText = "";
  if (quoteOrOrder === "quote") {
    notesText = "Quote: quoteNo";
  } else if (quoteOrOrder === "order") {
    notesText = "Order: orderNo";
  }
  for (let i = 0; i < postData.length; i++){
    notesText =  notesText + "<div class='table_notes_item'><p> <strong>Description:</strong> " + postData[i].description + "</p> <p><strong>Part No:</strong> " + postData[i].partNo + "</p></div>"; 
    console.log(notesText);
  }
  
  if (postData.length > 0 && quoteOrOrder === "quote") {
    this.productService.sendSPQuotes(postData).subscribe(
      (res) => {
        console.log(res, "resss");
        this.resetSelection();
        if (res.Message === "Success") {
          this.dataSharingService.setLoader(false);
          this.snackBar.open(res.Result, "", {
            duration: 2500,
          });
          this.addNotes.productNotes.productSerialNo = postData[0].productSerialNum;
          this.addNotes.productNotes.productNotes = notesText;
          console.log('this.addNotes.productNotes.productNotes', this.addNotes.productNotes.productNotes);
          this.addNotes.productNotes.updatedBy.operatorId = 2;
          console.log('myDate:', this.myDate);
          this.addNotes.productNotes.updatedOn = new Date();
          this.addNotes.productNotes.imageLoc = '';
          this.addNotes.productNotes.isDeleted = 0;
          this.addNotes.productNotes.deletedBy = null;
          this.addNotes.productNotesFile = [];
          
          this.productService.addProductNotes(this.addNotes).subscribe(data => {
            console.log("NoteData:", data)
            this.fetchProductNotes();
          });
        } else {
          this.dataSharingService.setLoader(false);
          this.snackBar.open("Quote Email not sent", "", {
            duration: 2500,
          });

        }
      },
      (err) => {
        this.snackBar.open("Quote Email not sent", "", {
          duration: 2500,
        });
      }
    );
  } else if (postData.length > 0 && quoteOrOrder === "order")  {
    this.productService.sendSPOrder(postData).subscribe(
      (res) => {
        console.log(res, "resss");
        this.resetSelection();
        if (res.Message === "Success") {
          this.dataSharingService.setLoader(false);
          this.snackBar.open(res.Result, "", {
            duration: 2500,
          });
          this.addNotes.productNotes.productSerialNo = postData[0].productSerialNum;
          this.addNotes.productNotes.productNotes = notesText;
          console.log('this.addNotes.productNotes.productNotes', this.addNotes.productNotes.productNotes);
          this.addNotes.productNotes.updatedBy.operatorId = 2;
          console.log('myDate:', this.myDate);
          this.addNotes.productNotes.updatedOn = new Date();
          this.addNotes.productNotes.imageLoc = '';
          this.addNotes.productNotes.isDeleted = 0;
          this.addNotes.productNotes.deletedBy = null;
          this.addNotes.productNotesFile = [];
          
          this.productService.addProductNotes(this.addNotes).subscribe(data => {
            console.log("NoteData:", data)
            this.fetchProductNotes();
          });
        } else {
          this.dataSharingService.setLoader(false);
          this.snackBar.open("Order Email not sent", "", {
            duration: 2500,
          });
        }
      },
      (err) => {
        this.snackBar.open("Order Email not sent", "", {
          duration: 2500,
        });
      }
    );
  } else {
    this.dataSharingService.setLoader(false);
    this.snackBar.open("Please select at least one checkbox", "", {
      duration: 2500,
    });
  }
}
  
closeFormPopup() {
  this.postFormSubmit = !this.postFormSubmit;
  this.sendUserForm.reset();
  this.resetSelection();
}

sendOthers() {
  let receivedListSparePartsResult = this.sparePartsResult?.filter((sp: any) => sp.checked);
  if (receivedListSparePartsResult.length > 0) {
    this.postFormSubmit = true;
    this.usPopupToggle = true;
    } else {
    this.snackBar.open("Please select at least one checkbox", "", {
      duration: 2500,
    });     
  }
}

sendOthersToSpareParts() {
  let postData: any = {};
  let emailIds: any = [];
  emailIds.push(this.sendUserForm.value.emailIds);
  postData.emailIds = emailIds;

  // let emailIds: any = this.sendUserForm.value.emailIds.split(",");
  // postData.emailIds = emailIds; 
  postData.message = this.sendUserForm.value.message;
  let receivedListSparePartsResult = this.sparePartsResult?.filter((sp: any) => sp.checked);
  postData.listSpareParts = receivedListSparePartsResult;
  console.log("sotospa pd:", postData);
  this.dataSharingService.setShowSmallLoader(true);
  let notesText = "Quote: quoteNo";
  for (let i = 0; i < receivedListSparePartsResult.length; i++){
    notesText =  notesText + "<div class='table_notes_item'><p> <strong>Description:</strong> " + receivedListSparePartsResult[i].description + "</p> <p><strong>Part No:</strong> " + receivedListSparePartsResult[i].partNo + "</p></div>"; 
    console.log(notesText);
  }
  this.productService.sendOthersToSpareParts(postData).subscribe(res => {
    if (res.Message == "Success") {
      this.dataSharingService.setShowSmallLoader(false);
      this.sendUserForm.reset();
      this.postFormSubmit = false;
      this.usPopupToggle = false;
      this.snackBar.open(res.Result, "", {
        duration: 2500,
      });
      this.resetSelection();
      this.addNotes.productNotes.productSerialNo = postData.listSpareParts[0].productSerialNum;
      this.addNotes.productNotes.productNotes = notesText;
      this.addNotes.productNotes.updatedBy.operatorId = 2;
      this.addNotes.productNotes.updatedOn = new Date();
      this.addNotes.productNotes.imageLoc = '';
      this.addNotes.productNotes.isDeleted = 0;
      this.addNotes.productNotes.deletedBy = null;
      this.addNotes.productNotesFile = [];
      
      this.productService.addProductNotes(this.addNotes).subscribe(data => {
        console.log("NoteData:", data)
        this.fetchProductNotes();
      });
      
    }
  });
}
  
  /**
   * Name : Sandhya Misal
   * Functionality :Navigate to dashboard page
   * Code Desc :Navigate to dashboard page
   **/
  ShowDashboardPage(){
    this.route.navigate(['/product-search']);
  }

  /**
   * Name : Sandhya Misal
   * Functionality :Navigate to product detail page
   * Code Desc :Navigate to product detail page
   **/
  ShowProductDetailPage(){
    let navigationExtras: NavigationExtras = {
      queryParams: {
          productId: this.productId
      },
  };
    this.route.navigate(['/product-details'], navigationExtras);
  }

  downloadFile(fileName){
    //const pdfUrl = "traceability/Rapid Opening Closure/UR01343/Installation Operation and Maintenance Manual – IOM.pdf";
    var str = fileName.split(' ').join('^'); 
    var form = document.createElement("form");
    console.log(str);
         form.action = `${environment.apiUrl}/downloadFile`;
         angular.element(form).append('<input type="hidden" name="fileName" value=' + str + '>');     
         form.method = "post";
         angular.element(document.body).append(form);
         form.submit();
   }

   /**
   * Name : Sandhya Misal
   * Functionality :Navigate to previous page
   * Code Desc :Navigate to previous page
   **/
  gotoProductDetails(){
    let navigationExtras: NavigationExtras = {
      queryParams: {
          productId: this.productId,
      }
  };
    this.route.navigate(['/product-details'], navigationExtras);
  }
  openDialog(image){
    this.dialog.open(DialogContentComponent, 
      {
        width:'100%',
        height:'100%',
        data: {image: image}
      });
  }

  transformLink(text: string): string {
    let stylizedText: string = '';
    if (text && text.length > 0) {
      for (let t of text.split(' ')) {
        if (t.length > 1 && (t.startsWith('www') || (t.startsWith('http')))){
          stylizedText += `<a href='${t}' target=_blank>${t}</a> `;
        } else {
          stylizedText += t + ' ';
        }
      }
      return stylizedText;
    } else return text;
  }

}

@Component({
  selector: 'dialog-image',
  templateUrl: 'dialog-image.html',
})

export class DialogContentComponent {
  constructor(
    public dialogRef: MatDialogRef<DialogContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

    }

    closePopup(): void {
      this.dialogRef.close();
    }
    
    EncodedImageURL = this.data.image;
    
    
    
    ngOnInit(): void {
      this.EncodedImageURL;
    }
  
}
