import { Component, OnInit } from '@angular/core';
import { DataSharingService } from '../services/data-sharing.service';
import { FormArray, FormBuilder, FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { FetchUserDetailsService } from '../services/fetch-user-details.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-form',
  templateUrl: './register-form.component.html',
  styleUrls: ['./register-form.component.css']
})
export class RegisterFormComponent implements OnInit {
  unrestrictedUser: boolean = true;
  selectBrand: object;
  brandArr: object;
  registeredUserValue: any;
  isError: boolean = false;
  postFormSubmit: boolean = false;
  registeredEmail: string;
  registerAccountHolder: boolean = false;
  reActivateErr: boolean = false;
  emailAcctStatus: string;

  addregisterForm = this.formBuilder.group({
    firstName: ['', Validators.required],
    lastName: ['', Validators.required],
    email: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    mobileNo: ['', Validators.required],
    brand: ['', Validators.required],
    orgName: ['', Validators.required],
    location: [''],
    department: [''],
    jobTitle: ['']
  });

  constructor(public dataSharingService: DataSharingService, private formBuilder: FormBuilder, private userService: FetchUserDetailsService, private snackBar: MatSnackBar, private router: Router) { 
  }

  ngOnInit(): void {
    this.dataSharingService.setHeader(true);
    this.fetchBrandDetails();
  }

  onSubmit() {
    this.registeredEmail = this.addregisterForm.controls['email'].value;
    this.registeredUserValue = this.addregisterForm.value;
    this.registeredUserValue.approvalStatus = "Pending";
    this.registeredUserValue.userTypeId = 0;
    this.registeredUserValue.accessLevel = 50;
    this.registeredUserValue.role = ['User']; 
    this.registeredUserValue.approvedOn = new Date();
    this.registeredUserValue.status = "Active"
    this.registeredUserValue.regionalManager = "";
    this.registeredUserValue.currency = "EUR"
    this.dataSharingService.setLoader(true);
    this.userService.addUserOnBehalf(this.registeredUserValue).subscribe((res) => {
      this.dataSharingService.setLoader(false);
      this.addregisterForm.reset();
      this.isError = res.message;
      this.registerAccountHolder = false;
      this.postFormSubmit = true;
    },
      (err) => {
        this.dataSharingService.setLoader(false);
        this.registerAccountHolder = false;
        this.isError = err.error.message;
        this.postFormSubmit = true;
        console.log(err);

        if (err.status == 302 && err.error.message == "An Inactive user already exists with same email") {
          this.postFormSubmit = false;
          this.registerAccountHolder = true;
        }
      // this.snackBar.open(err.error.details[0],'', {
      //   duration: 3000,
      // })
    })
  }

  get registerForm() { return this.addregisterForm.controls };


  fetchBrandDetails(){
    this.userService.fetchBrandDetails().subscribe(data => {
      this.selectBrand = data.filter(a => a);
      this.brandArr = Object.keys(this.selectBrand).map(key => ({ id: key, value: this.selectBrand[key] }));
    }, err =>{
      console.log(err);
    })
  }

  goBack() {
    this.router.navigate(['/login'])
  }

  useraccountreactivate() {
    this.dataSharingService.setLoader(true);
    this.userService.userAcctReactivate(this.emailAcctStatus).subscribe(res => {
      this.dataSharingService.setLoader(false);
      this.emailAcctStatus = '';
      this.addregisterForm.reset();
      this.registerAccountHolder = false;
      this.postFormSubmit = true;
      this.isError = res.message;
    })
  }

}
