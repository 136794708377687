<div>
  <div class="select-wrap">
    
    <ejs-grid #grid [dataSource]='data' [allowSorting]='true' [allowSelection]='true' allowFiltering='true' [filterSettings]='filter' width='850' allowScrolling='true' [toolbar]='toolbar' 
    (rowSelected)='rowSelected($event)'>
        <e-columns>
          <e-column type='checkbox' [allowFiltering]='false' [allowSorting]='false' width='60'></e-column>
          <e-column field='firstName' headerText='NAME' width='120'></e-column>
          <e-column field='email' headerText='EMAIL' width='150' matTooltip="Info about the action"></e-column>
          <e-column field='location' headerText='LOCATION' width='120'></e-column>
          <e-column field='jobTitle' headerText='JOB TITLE' width='120'></e-column>
          <e-column field='department' headerText='DEPARTMENT' width='120'></e-column>
          <e-column field='approvalStatus' headerText='STATUS' width='120'>
          <ng-template #template let-data>
            <div class="myDIV" *ngIf="data.approvalStatus === 'Pending'">  {{data.approvalStatus}}&nbsp;
                        <img class="reject" src="../assets/user_reject.png" (click)="userReject(data)" />&nbsp;&nbsp;
                        <img class="approve" src="../assets/user_approve.png" (click)="userApprove(data)" />&nbsp;&nbsp;
                      </div>
            <div class="myDIV" *ngIf="data.approvalStatus === 'Approved'">{{data.approvalStatus}}
              <img class="approve" src="../assets/user_revert.png" (click)="userRevoke(data)"/>
            </div>
            <div *ngIf="data.approvalStatus === 'Rejected'">  
              <span>{{data.approvalStatus}}</span>
            </div>
            <div *ngIf="data.approvalStatus === 'Revoked'">  
              <span>{{data.approvalStatus}}</span>
            </div>
        </ng-template>
          </e-column>
      </e-columns>
    </ejs-grid>
    </div>
  
  