import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { ProductService } from "src/app/services/product.service";
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import { IsendOthers, IspareParts, ProductDetails } from "src/app/models/productDetails";
import { DataSharingService } from "src/app/services/data-sharing.service";
import { Location } from "@angular/common";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { AddNoteComponent } from "src/app/add-note/add-note.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "../../../environments/environment";
import jwt_decode from "jwt-decode";
import { FetchUserDetailsService } from "src/app/services/fetch-user-details.service";
import { MsalService } from "@azure/msal-angular";
// import { OneSignal } from "onesignal-ngx";
import { Validators, FormBuilder } from "@angular/forms";

declare var angular: any;
export interface DialogData {
  image: string;
  productId: number;
  notes: any;
  productLine: any;
}
@Component({
  selector: "app-product-details",
  templateUrl: "./product-details.component.html",
  styleUrls: ["./product-details.component.css"],
})
export class ProductDetailsComponent implements OnInit, OnDestroy {

  sendUserForm = this.formBuilder.group({
    emailIds: ['', Validators.required],
    message: ['', Validators.required]
  });


  [x: string]: any;
  public productId: string;
  public qrcodeId: string;
  public uoId: any;
  unrestrictedUser: boolean = true;
  productDetails: ProductDetails[];
  productDetailObject = new ProductDetails();
  newProducts: any;
  loader: boolean = true;
  isProductNotes: boolean = false;
  isOrgPrice: boolean = false;
  searchword: any;
  notes: any;
  productFirstAttributes: string;
  specificNotesDetails: any;
  notesLength: number;
  notesFileCount: number;
  notesCount: number;
  isNotes: boolean = false;
  nextNote: any;
  accessToken: any;
  message: string;
  validateEmail1: boolean = false;
  validateEmail2: boolean = false;
  validateURL1: boolean = false;
  validateURL2: boolean = false;
  firstSubscribeBtn: boolean = false;
  subscribeBtn: boolean = false;
  unsubscribeBtn: boolean = false;
  notesCountActualValue;
  isgetSpareParts: boolean = false;
  sparePartsResult: any;
  sparePartsData: IspareParts[] = [];
  isselectAll: boolean = false;
  productLineId: any;
  descSPData: any;
  userOperatorId: any;
  productSubscriptionData: any;
  productSerialNum = '';
  subscribedData: any;
  prodSubscribed: any;
  osTagText;
  osTagClass;
  prodSubscribtionId: boolean;
  subscriptionData: any;
  getProdSubscriptionId: boolean;
  prodSubData: any;
  postFormSubmit: boolean =  false;
  receivedListSparePartsResult: any;
  statusMessage: any;
  usPopupToggle: boolean = false;
  prodSubsId: any;
  
  addNotes = {
    productNotes: {
      productSerialNo: null,
      productNotes: null,
      imageLoc: null,
      updatedBy: { operatorId: 0 },
      updatedOn: null,
      isDeleted: null,
      deletedBy: null,
      quoteText: null
    },
    productNotesFile: [{
      filepath: null,
      fileType: null,
    }]
  };
  
  //prod
  AZURE_BLOB_END_POINT =
    "https://prodcircorsmartsa.blob.core.windows.net/traceability/";
  QUESTION_MARK = "?";
  AZURE_BLOB_SAS_KEY =
    "sv=2019-12-12&ss=bfqt&srt=sco&sp=rwdlacupx&se=2025-12-31T22:31:05Z&st=2021-01-05T14:31:05Z&spr=https&sig=yGEgOLDyUbQLAYoOqPp%2FOsfdiWZFHho57UQ0QdLkxFA%3D";
  //test
  //  AZURE_BLOB_END_POINT = 'https://circortesttraceabilitysa.blob.core.windows.net/traceability/'
  //   QUESTION_MARK = '?'
  //   AZURE_BLOB_SAS_KEY = 'sv=2019-12-12&ss=b&srt=sco&sp=rwdlacx&se=2021-05-31T13:02:14Z&st=2020-11-06T05:02:14Z&spr=https&sig=VpbVv3y0ct%2Bm9jmRz%2FKTPsZ52OpujKieBGhQSdg3rJg%3D'

  // showProductDetails = new ProductDetails();


  constructor(
    private productService: ProductService,
    private authService: MsalService,
    private route: Router,
    private routes: ActivatedRoute,
    public dataSharingService: DataSharingService,
    private _location: Location,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private fetchCount: FetchUserDetailsService,
    private formBuilder: FormBuilder,
    // private os: OneSignal
  ) {
    this.routes.queryParams.subscribe((params) => {
      if (params["productId"] != null || params["uoId"] != null) {
        this.productId = params["productId"];
        this.uoId = params["uoId"];
        sessionStorage.setItem('productId', this.productId);
        sessionStorage.setItem('uoId', this.uoId);
      }
      if(params["qrcode"] != null)  {
        this.qrcodeId = params["qrcode"];
        sessionStorage.setItem('qrcodeId', this.qrcodeId);
      }
    });

    this._location.replaceState(this._location.path().split("?")[0], "");
    this.dataSharingService.setHeader(false);

    if (localStorage.getItem("accessToken")) {
      this.dataSharingService.setUnrestrictedUser(false);
      this.unrestrictedUser = false;
        let decodedToken = jwt_decode(localStorage.getItem("accessToken"));
        // live#circorsmartapp@outlook.com
        this.email = decodedToken['unique_name'].includes('#') ? decodedToken['unique_name'].split("#").pop() : decodedToken['unique_name'];
        console.log("upn", this.email, decodedToken['unique_name']);
      } else {
        this.route.navigate(['/login']);
      }

    this.fetchCount.fetchUseryEmail(this.email).subscribe(data => {
      this.dataSharingService.setUserTypeId(data?.userTypeId?.userTypeId);
      this.userOperatorId = data.operatorId;
      this.currency = data.currency;
      console.log("uTypeid", this.dataSharingService.getUserTypeId());
    });

    this.dataSharingService.fetchUserRole.subscribe((res: string) => {
      this.role = res;
    })

    /* ----- usd factor feature start-------- */
    // this.productService.fetchProductDetails(this.productId).subscribe((data) => {
    //   this.fetchpdData = data[0].productLineId;
    //   this.productService.getAllProductLine().subscribe((res: any) => {
    //     this.getAllPline = res;
    //     this.plcurrency = this.getAllPline.find(pl => pl.productLine === this.fetchpdData);
    //     this.plusdcurrency = this.plcurrency.usdfactor
    //   })
    // })

    /* ----- usd factor feature end -------- */

    console.log("unrestrictedUser", this.unrestrictedUser);

  }
   get form() { return this.sendUserForm.controls; }

  getNotesUpdatedUserOperatorId(notesOperatorId) {
    return this.userOperatorId === notesOperatorId;
  }
  

    /* ----- future reference not needed start -------- */
  // getWithExpiry() {
  //     const itemStr = localStorage.getItem("accessToken");
  //   if (!itemStr) {
  //     return null;
  //   }
  //   const item = JSON.parse(itemStr);
  //   const now = new Date();
  //   if (now.getTime() > item.expiry) {
  //     localStorage.removeItem("accessToken");
  //     this.authService
  //       .acquireTokenPopup(OAuthSettings)
  //       .then((tokenResponse) => {
  //         console.log(tokenResponse.accessToken);
  //         this.setAccessToken(tokenResponse.accessToken);
  //         return tokenResponse.accessToken;
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //         this.route.navigate(["/login"]);
  //       });
  //   }
  //   return item.value;
  // }

  // setAccessToken(accessToken) {
  //   const now = new Date();
  //   const item = {
  //     value: accessToken,
  //     expiry: now.getTime() + 500000,
  //   };
  //   localStorage.setItem("accessToken", JSON.stringify(item));
  // }
  
  /* ----- future reference not needed end -------- */
  

  ngOnInit(): void {
    this.productId = this.productId ?? sessionStorage.getItem('productId');
    this.qrcodeId = this.qrcodeId ?? sessionStorage.getItem('qrcodeId');
    this.uoId = this.uoId ?? sessionStorage.getItem('uoId');
    // this.accessToken = this.getWithExpiry();    

    /* ----- usd factor feature start -------- */
    // this.productService.fetchProductDetails(this.productId).subscribe((data) => {
    //   this.fetchpdData = data[0].productLineId;
    //   this.productService.getAllProductLine().subscribe((res: any) => {
    //     this.getAllPline = res;
    //     this.plcurrency = this.getAllPline.find(pl => pl.productLine === this.fetchpdData);
    //     this.plusdcurrency = this.plcurrency.usdfactor
    //   })
    // })
    /* ----- usd factor feature end-------- */


     if (this.qrcodeId) {
      this.qrCodeCheck();
    }
  
    console.log("uo", this.uoId);

    this.prodSubscribtionId = this.getProductSubscription(this.uoId, this.productId);
    if (!this.prodSubscribtionId) {
      this.subscribeBtn = false;
      this.unsubscribeBtn = false;
      this.firstSubscribeBtn = true;
    } else {
      this.getProductSubscription(this.uoId, this.productId);
    }  
    this.fetchPrductDetails();
    this.getProductSubscriptionImethod();
    window.addEventListener("pageshow", function (event) {
      var historyTraversal =
        event.persisted ||
        (typeof window.performance != "undefined" &&
          window.performance.navigation.type === 2);
      if (historyTraversal) {
        // Handle page restore.
        window.location.reload();
      }
    });

    // if (localStorage.getItem("accessToken") != null && localStorage.getItem("accessToken") != undefined) {
    //   this.route.navigate(['/login']);
    // }
  }
  ngOnDestroy(): void {
    sessionStorage.removeItem('productId');
    sessionStorage.removeItem('qrcodeId');
  }

  getProductSubscription(userId, serialNo) {
    this.productService.getProductSubscription(userId, serialNo).subscribe(res => {
      this.dataSharingService.setSubscribtionId(res.data.data.subscriptionId);
      console.log("psress:", res);
      this.productSubscriptionData = res?.data; 
      this.subscribedData = res.data.data.subscribed;

      if (this.subscribedData == true) {
        this.firstSubscribeBtn = false;
        this.subscribeBtn = false;
        this.unsubscribeBtn = true;
      } else {
        this.unsubscribeBtn = false;
        this.firstSubscribeBtn = false;
        this.subscribeBtn = true;
      }
     
      if (res.data) {
        this.prodSubscribed = res.data.data.subscribed; 
      } 
      return true;
    })
    return false;
  }

  sendOSTag(tag: string) {
    this.os.sendTag("serialNo", tag);
    let postData = {
      "serialNo" : this.productId,
      "subscribed": true
    };
    this.productService.saveProductSubscription(this.uoId, postData).subscribe();
    this.subscribeBtn = false;
    this.firstSubscribeBtn = false;
    this.unsubscribeBtn = true;
  }

  getProductSubscriptionImethod() {
    return new Promise((resolve, reject) => {
      this.productService.getProductSubscription(this.uoId, this.productId).subscribe(res => {
        if (res.data) {
          console.log("getProdsub", res);
          resolve(res.data.data) 
        } else {
          reject("cannot get subscription id");
        }
      })
    });
  }

  // getProductSubscriptionImethod1() {
  //     this.productService.getProductSubscription(this.uoId, this.productId).subscribe(res => {
  //       if (res.data) {
  //         console.log("getProdsub", res);
  //         this.prodSubsId = {...res.data.data};
  //       } 
  //     })
  // }

  async updateOSTag(tag: string) {
    this.os.sendTag("serialNo", tag);
    this.prodSubData = await this.getProductSubscriptionImethod();
    let postData = {
      "subscriptionId": this.prodSubData?.subscriptionId,
      "userOperatorId": this.prodSubData?.userOperatorId,
      "productSerialNumber": this.prodSubData?.productSerialNumber,
      "subscribed": true
    };
    this.productService.updateProductSubscription(postData).subscribe();
    this.subscribeBtn = false;
    this.firstSubscribeBtn = false;
    this.unsubscribeBtn = true;
  }

  async deleteOSTag() {
    this.os.deleteTag("serialNo");
    this.prodSubData =  await this.getProductSubscriptionImethod();
    console.log("subscriptionData - subid", this.prodSubData, this.prodSubData?.subscriptionId, this.prodSubData?.userOperatorId);
    
    let postData = {
      "subscriptionId": this.prodSubData?.subscriptionId,
      "userOperatorId": this.prodSubData?.userOperatorId,
      "productSerialNumber": this.prodSubData?.productSerialNumber,
      "subscribed": false
    };
    this.productService.updateProductSubscription(postData).subscribe();
    this.unsubscribeBtn = false;
    this.firstSubscribeBtn = false;
    this.subscribeBtn = true;
  }

  fetchPrductDetails() {
    if (this.productId) {
      console.log("in fetchMethod", this.productId);
      this.productService
        .fetchProductDetails(this.productId)
        .subscribe((data) => {
          this.loader = false;
          this.productDetails = data;
          this.newProducts = [];
          this.newProducts = this.productDetails.filter((data) => data.serialNumber == this.productId);
          console.log("newProduct", this.newProducts);
          this.productDetailObject = this.newProducts[0];
          this.productFirstAttributes = this.productDetailObject.prodAttributes[0].attributeValue;
          this.productLineId = this.productDetailObject.productLineId;
          this.docLocation = this.productDetailObject.docLocation;
          this.checkQuoteAndOrderForProd();

          if (
            this.productDetailObject.contEmail1.match(
              /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/
            )
          ) {
            this.validateEmail1 = true;
          }

          if (
            this.productDetailObject.contEmail1.match(
              /^(https:|http:|www\.)\S*/
            )
          ) {
            this.validateURL1 = true;
          }
          if (
            this.productDetailObject.contEmail2?.match(
              /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/
            )
          ) {
            this.validateEmail2 = true;
          }
          if (
            this.productDetailObject.contEmail2?.match(
              /^(https:|http:|www\.)\S*/
            )
          ) {
            this.validateURL2 = true;
          }
         
        }),
        (error) => {
          console.log(error);
        };
      this.dataSharingService.fetchNotes.subscribe((data) => {
        if (data == true) {
          this.fetchProductNotes();
        }
      });
      this.fetchProductNotes();
      this.fetchSpareParts(this.productId);
    }
    // this.qrCodeCheck();
  }

  qrCodeCheck() {
        if (this.qrcodeId != null && this.qrcodeId != undefined) {
      console.log("in fetchMethod", this.qrcodeId);
      this.productService
        .fetchqrCodeDetails(this.qrcodeId)
        .subscribe((data) => {
          this.loader = false;
          this.productDetails = data;
          this.newProducts = [];
          //var tempQr="https://www.circor.com/services/TAGS/"+this.qrcodeId;
          this.newProducts = data;
          //  this.productDetails.filter((data) =>
          //data.qrCodeId == tempQr);
          console.log("newProduct", this.newProducts);
          this.productDetailObject = this.newProducts[0];
          this.productId = this.productDetailObject.serialNumber;
          this.productLineId = this.productDetailObject.productLineId;
          console.log("productObject", this.productDetailObject);
          this.checkQuoteAndOrderForProd();
          this.dataSharingService.fetchNotes.subscribe((data) => {
            if (data == true) {
              this.fetchProductNotes();
            }
          });
          this.fetchProductNotes();
          this.fetchSpareParts(this.productId);
        }),
        (error) => {
          console.log(error);
        };
    }
  }

  fetchSpareParts(productId: any) {
    this.productService.fetchSpareParts(productId).subscribe((res) => {
      console.log("sparts", res);
      this.sparePartsResult = res;
      if (res?.some(r => r.price > 0)) {
        this.isOrgPrice = true;
      }
      if (res.length > 0 ) {
        this.isgetSpareParts = true;
      }
    });
  }

  fetchProductNotes() {
    this.productService
    .fetchProductNotes(this.productId)
    .subscribe((notes) => {
      this.notes = notes;
      this.convertNotes();
      console.log("notes:", this.notes);
      this.notesLength = Object.keys(this.notes).length;
      console.log("notes length:", Object.keys(this.notes).length);
      // console.log("productNotesFile length:",Object.keys(this.notes.productNotesFile).length);
      for (var notesFile of this.notes) {
        this.notesFileCount = Object.keys(
          notesFile.productNotesFile
        ).length;
      }
    }),
    (error) => {
      console.log(error);
    };
  }

  isUserType() {
    console.log("uType", this.dataSharingService.getUserTypeId());
    return this.dataSharingService.getUserTypeId();
  }
  
  checkQuoteAndOrderForProd() {
      this.productService.checkQuoteandOrder(this.productLineId).subscribe((res) => {
          this.isCheckQuoteOrder = res;
          console.log("quoteorder", this.isCheckQuoteOrder);
        });
  }

  CheckBoxVisibleForQuoteOrder() {
    return (this.isCheckQuoteOrder && (this.isCheckQuoteOrder.quoteAdmin || this.isCheckQuoteOrder.orderAdmin));
  }

  selectAll(selectAll) {
    console.log("spresult", this.sparePartsResult, selectAll);
    this.sparePartsResult = this.sparePartsResult.map((sp: any) => {
      sp.checked = selectAll;
      return sp;
    });
  }
  // checkForAllSelect() {
  //   let selectedSP = this.sparePartsResult.filter((sp: any) => {
  //     return sp.checked === true;
  //   });
  //   if (selectedSP.length === this.sparePartsResult.length) {
  //     this.isselectAll = true;
  //   } else if (selectedSP.length > 0) {
  //     this.isselectAll = false;
  //   }
  // }

  checkForAllSelect() {
   
    let valveSelectedItem = this.sparePartsResult.filter((sp: any) => {
      return sp.description == "REPLACEMENT VALVE" && sp.checked === true;
    });
   
    let valveSelectedNextItems = this.sparePartsResult.filter((sp: any) => {
      return sp.description == "3.1 CERTIFICATES FOR VALVE" || sp.description == "MATERIAL SURCHARGE FOR VALVE";
    });

    // let valveSelectedNextItems1 = this.sparePartsResult.forEach((sp: any) => {
    //   return sp.description == "3.1 CERTIFICATES FOR VALVE" && sp.description == "MATERIAL SURCHARGE FOR VALVE";
    // });

    // if (valveSelectedNextItems1.length > 0) {
    //   console.log("sdfsa");
    // }

    if (valveSelectedItem.length > 0 && this.productLineId == "ARV") {
      valveSelectedNextItems.forEach(element => {
        element.checked = true;
      });
    
    } else {
      valveSelectedNextItems.forEach(element => {
        element.checked = false;
      });
    }
    
    let selectedSP = this.sparePartsResult.filter((sp: any) => {
      return sp.checked === true;
    });

    if (selectedSP.length === this.sparePartsResult.length) {
      this.isselectAll = true;
    } else if (selectedSP.length > 0) {
      this.isselectAll = false;
    }
  }

  checkForDisable(description) {
    let selectedSPValve = this.sparePartsResult.filter((sp: any) => {
      return sp.description == "REPLACEMENT VALVE" && sp.checked === true;
    });
    if ((selectedSPValve.length > 0 && this.productLineId == "ARV") && (description == "3.1 CERTIFICATES FOR VALVE" || description == "MATERIAL SURCHARGE FOR VALVE")) {
      return true;
    } else {
      return false;
    }
  }

  resetSelection() {
    this.sparePartsResult = this.sparePartsResult.map((sp: any) => {
      sp.checked = false;
      return sp;
    });
    this.isselectAll = false;
  }

  convertNotes() {
    let quoteNo = 0;
    let orderNo = 0;
    for (let i = 0; i < this.notes.length; i++){
      let note = this.notes[i];
      let noteText = note.productNotes.productNotes;
      if (noteText.startsWith("Quote: quoteNo")) {
        noteText = noteText.replace("quoteNo", (quoteNo + 1) + "</span>");
        noteText = "<div class='table_notes'><span class='fb'>" + noteText + "</div>";
        quoteNo = quoteNo + 1;
        this.notes[i].productNotes.productNotes = noteText;
      } else if (noteText.startsWith("Order: orderNo")) {
        noteText = noteText.replace("orderNo", (orderNo + 1) + "</span>");
        noteText = "<div class='table_notes'><span class='fb'>" + noteText + "</div>";
        orderNo = orderNo + 1;
        this.notes[i].productNotes.productNotes = noteText;
      }
    }
  }

  sparePartsQuotesAndOrder(quoteOrOrder: string) {
    this.dataSharingService.setLoader(true);
    let postData = this.sparePartsResult.filter((sp: any) => sp.checked);
    console.log(postData);
    let notesText = "";
    if (quoteOrOrder === "quote") {
      notesText = "Quote: quoteNo";
    } else if (quoteOrOrder === "order") {
      notesText = "Order: orderNo";
    }
    for (let i = 0; i < postData.length; i++){
      notesText =  notesText + "<div class='table_notes_item'><p> <strong>Description:</strong> " + postData[i].description + "</p> <p><strong>Part No:</strong> " + postData[i].partNo + "</p></div>"; 
      console.log(notesText);
    }
    
    if (postData.length > 0 && quoteOrOrder === "quote") {
      // this.productService.sendSPQuotes(postData, this.productFirstAttributes).subscribe(
      this.productService.sendSPQuotes(postData).subscribe(
        (res) => {
          console.log(res, "resss");
          this.resetSelection();
          if (res.Message === "Success") {
            this.dataSharingService.setLoader(false);
            this.snackBar.open(res.Result, "", {
              duration: 2500,
            });
            this.addNotes.productNotes.productSerialNo = postData[0].productSerialNum;
            this.addNotes.productNotes.productNotes = notesText;
            console.log('this.addNotes.productNotes.productNotes', this.addNotes.productNotes.productNotes);
            this.addNotes.productNotes.updatedBy.operatorId = 2;
            console.log('myDate:', this.myDate);
            this.addNotes.productNotes.updatedOn = new Date();
            this.addNotes.productNotes.imageLoc = '';
            this.addNotes.productNotes.isDeleted = 0;
            this.addNotes.productNotes.deletedBy = null;
            this.addNotes.productNotesFile = [];
            
            this.productService.addProductNotes(this.addNotes).subscribe(data => {
              console.log("NoteData:", data)
              this.fetchProductNotes();
            });
          } else {
            this.dataSharingService.setLoader(false);
            this.snackBar.open("Quote Email not sent", "", {
              duration: 2500,
            });
  
          }
        },
        (err) => {
          this.snackBar.open("Quote Email not sent", "", {
            duration: 2500,
          });
        }
      );
    } else if (postData.length > 0 && quoteOrOrder === "order")  {
      this.productService.sendSPOrder(postData).subscribe(
        (res) => {
          console.log(res, "resss");
          this.resetSelection();
          if (res.Message === "Success") {
            this.dataSharingService.setLoader(false);
            this.snackBar.open(res.Result, "", {
              duration: 2500,
            });
            this.addNotes.productNotes.productSerialNo = postData[0].productSerialNum;
            this.addNotes.productNotes.productNotes = notesText;
            console.log('this.addNotes.productNotes.productNotes', this.addNotes.productNotes.productNotes);
            this.addNotes.productNotes.updatedBy.operatorId = 2;
            console.log('myDate:', this.myDate);
            this.addNotes.productNotes.updatedOn = new Date();
            this.addNotes.productNotes.imageLoc = '';
            this.addNotes.productNotes.isDeleted = 0;
            this.addNotes.productNotes.deletedBy = null;
            this.addNotes.productNotesFile = [];
            this.productService.addProductNotes(this.addNotes).subscribe(data => {
              console.log("NoteData:", data)
              this.fetchProductNotes();
            });
          } else {
            this.dataSharingService.setLoader(false);
            this.snackBar.open("Order Email not sent", "", {
              duration: 2500,
            });
          }
        },
        (err) => {
          this.snackBar.open("Order Email not sent", "", {
            duration: 2500,
          });
        }
      );
    } else {
      this.dataSharingService.setLoader(false);
      this.snackBar.open("Please select at least one checkbox", "", {
        duration: 2500,
      });
    }
  }

  closeFormPopup() {
    this.postFormSubmit = !this.postFormSubmit;
    this.sendUserForm.reset();
    this.resetSelection();
  }

  sendOthers() {
    let receivedListSparePartsResult = this.sparePartsResult?.filter((sp: any) => sp.checked);
    if (receivedListSparePartsResult.length > 0) {
      this.postFormSubmit = true;
      this.usPopupToggle = true;
      } else {
      this.snackBar.open("Please select at least one checkbox", "", {
        duration: 2500,
      });     
    }
  }

  sendOthersToSpareParts() {
    let postData: any = {};
    let emailIds: any = [];
    emailIds.push(this.sendUserForm.value.emailIds);
    postData.emailIds = emailIds;

    // let emailIds: any = this.sendUserForm.value.emailIds.split(",");
    // postData.emailIds = emailIds; 
    postData.message = this.sendUserForm.value.message;
    let receivedListSparePartsResult = this.sparePartsResult?.filter((sp: any) => sp.checked);
    postData.listSpareParts = receivedListSparePartsResult;
    console.log("sotospa pd:", postData);
    this.dataSharingService.setShowSmallLoader(true);
    let notesText = "Quote: quoteNo";
    for (let i = 0; i < receivedListSparePartsResult.length; i++){
      notesText =  notesText + "<div class='table_notes_item'><p> <strong>Description:</strong> " + receivedListSparePartsResult[i].description + "</p> <p><strong>Part No:</strong> " + receivedListSparePartsResult[i].partNo + "</p></div>"; 
      console.log(notesText);
    }
    this.productService.sendOthersToSpareParts(postData).subscribe(res => {
      if (res.Message == "Success") {
        this.dataSharingService.setShowSmallLoader(false);
        this.sendUserForm.reset();
        this.postFormSubmit = false;
        this.usPopupToggle = false;
        this.snackBar.open(res.Result, "", {
          duration: 2500,
        });
        this.resetSelection();
        this.addNotes.productNotes.productSerialNo = postData.listSpareParts[0].productSerialNum;
        this.addNotes.productNotes.productNotes = notesText;
        this.addNotes.productNotes.updatedBy.operatorId = 2;
        this.addNotes.productNotes.updatedOn = new Date();
        this.addNotes.productNotes.imageLoc = '';
        this.addNotes.productNotes.isDeleted = 0;
        this.addNotes.productNotes.deletedBy = null;
        this.addNotes.productNotesFile = [];
        
        this.productService.addProductNotes(this.addNotes).subscribe(data => {
          console.log("NoteData:", data)
          this.fetchProductNotes();
        });
        
      }
    });
  }


  //Open Partner Link
  openLink(link: string) {
    let url: string = "";
    if (!/^http[s]?:\/\//.test(link)) {
      url += "http://";
    }

    url += link;
    window.open(url, "_blank");
  }

  showProductDetails() {    
    let navigationExtras: NavigationExtras = {
      queryParams: {
        productId: this.productId,
      },
    };
    this.route.navigate(["/product-details"], navigationExtras);
  }

  //redirect to product faq page
  ShowFAQPage() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        productId: this.productId,
      },
    };

    this.route.navigate(["/faq"], navigationExtras);
  }
  //redirect to instructional page
  ShowInstructionalManualPage() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        productId: this.productId,
      },
    };
    this.route.navigate(["/instructional-manual"], navigationExtras);
  }

  //redirect to dashboard page
  ShowDashboardPage() {
    this.route.navigate(["/product-search"]);
  }

  /**
   * Name : Sandhya Misal
   * Functionality :Show product Notes in details
   * Code Desc :Show product Notes in details with username, notes images and attachments
   **/
  showProductNotes(productNotesId, i) {
    this.notesCountActualValue = i;
    this.notesCount = i + 1;
    console.log("notesCount", this.notesCount);
    this.isProductNotes = true;
    console.log("productNotesId", productNotesId);
    this.isNotes = false;
    // for(var i=0; i<= Object.keys(this.notes).length;i++){
    //    this.notes[i]
    // }
    // console.log("specificNotesDetails", this.notes[5]);
    this.specificNotesDetails = this.notes.filter(
      (data) => data.productNotes.productNotesId == productNotesId
    );

    for (let notesFile of this.specificNotesDetails[0].productNotesFile) {
      if (
        notesFile.fileName == null ||
        notesFile.fileName == undefined ||
        notesFile.fileName == ""
      ) {
        var res = notesFile.filepath.split("/");

        var lastSlashValue = res[res.length - 1];
        notesFile.fileName = lastSlashValue.replaceAll("%20", " ");

        if (
          notesFile.fileType === "image/jpeg" ||
          notesFile.fileType === "image/png"
        ) {
          notesFile.filepath =
            this.AZURE_BLOB_END_POINT +
            notesFile.filepath +
            this.QUESTION_MARK +
            this.AZURE_BLOB_SAS_KEY;
        }
      }
    }
    console.log("this.specificNotesDetails", this.specificNotesDetails);
    // console.log("this.specificNotesDetails", this.specificNotesDetails);
  }

  /**
   * Name : Sandhya Misal
   * Functionality :Go to the next note
   * Code Desc :Go to the next note
   **/
  nextNotes() {
    console.log("notesCountActualValue", this.notesCountActualValue);
    console.log("notesCount", this.notesCount);
    console.log("Notes:", this.notes);
    this.isProductNotes = true;
    this.notesCountActualValue = this.notesCountActualValue + 1;
    this.notesCount = this.notesCount + 1;
    console.log("notesCount", this.notesCount);
    this.isNotes = true;
    this.nextNote = this.notes[this.notesCountActualValue];
    console.log("nn:", this.nextNote);
    
    for (let notesFile of this.nextNote.productNotesFile) {
      if (
        notesFile.fileName == null ||
        notesFile.fileName == undefined ||
        notesFile.fileName == ""
      ) {
        var res = notesFile.filepath.split("/");

        var lastSlashValue = res[res.length - 1];
        notesFile.fileName = lastSlashValue.replaceAll("%20", " ");
        if (
          notesFile.fileType === "image/jpeg" ||
          notesFile.fileType === "image/png"
        ) {
          notesFile.filepath =
            this.AZURE_BLOB_END_POINT +
            notesFile.filepath +
            this.QUESTION_MARK +
            this.AZURE_BLOB_SAS_KEY;
        }
      }
    }
  }
  /**
   * Name : Sandhya Misal
   * Functionality :Go to the previous note
   * Code Desc :Go to the previous note
   **/
  previousNotes() {
    console.log("notesCountActualValue", this.notesCountActualValue);
    console.log("notesCount", this.notesCount);
    console.log("Notes:", this.notes);
    this.isProductNotes = true;
    this.notesCountActualValue = this.notesCountActualValue - 1;
    this.notesCount = this.notesCount - 1;
    console.log("notesCount", this.notesCount);
    this.isNotes = true;
    this.nextNote = this.notes[this.notesCountActualValue];
    console.log("pn:", this.nextNote);

    for (let notesFile of this.nextNote.productNotesFile) {
      if (
        notesFile.fileName == null ||
        notesFile.fileName == undefined ||
        notesFile.fileName == ""
      ) {
        var res = notesFile.filepath.split("/");
        var lastSlashValue = res[res.length - 1];
        notesFile.fileName = lastSlashValue.replaceAll("%20", " ");
        if (
          notesFile.fileType === "image/jpeg" ||
          notesFile.fileType === "image/png"
        ) {
          notesFile.filepath =
            this.AZURE_BLOB_END_POINT +
            notesFile.filepath +
            this.QUESTION_MARK +
            this.AZURE_BLOB_SAS_KEY;
        }
      }
    }
  }
  /**
   * Name : Sandhya Misal
   * Functionality :Go to previous page
   * Code Desc :Go to previous page
   **/
  gotoPreviousPage() {
    this.isProductNotes = false;
    this.isNotes = false;
  }

  /**
   * Name : Sandhya Misal
   * Functionality :Download image/attachment
   * Code Desc :Download image/attachment
   **/
  downloadFile(fileName) {
    //const pdfUrl = "traceability/Rapid Opening Closure/UR01343/Installation Operation and Maintenance Manual – IOM.pdf";
    var str = fileName.split(" ").join("^");
    var form = document.createElement("form");
    console.log(str);
    //   form.action = "https://test-traceability-service.azurewebsites.net/downloadFile";
    form.action = `${environment.apiUrl}/downloadFile`;
    angular
      .element(form)
      .append('<input type="hidden" name="fileName" value=' + str + ">");
    form.method = "post";
    angular.element(document.body).append(form);
    form.submit();
  }

  openDialog() {
    console.log("productDetailObjectIn popup", this.productDetailObject);
    this.dialog.open(AddNoteComponent, {
      width: "500px",
      height: "320px",
      data: {
        productId: this.productDetailObject.serialNumber,
        productLine: this.productDetailObject.productLineId,
        notes: this.notes,
      },
    });
  }

  openPopup(image) {
    this.dialog.open(PopUpContentComponent, {
      data: { image: image },
    });
  }

  /**
   * Name : Sandhya Misal
   * Functionality :Calling delete notes api
   * Code Desc :It will delete perticular notes
   **/
  // deleteNotes(productNotesId){
  // console.log("this.specificNotesDetailssssssssssssssss", this.specificNotesDetails);
  // this.deleteNote = this.notes.filter(data=>
  // data.productNotes.productNotesId == productNotesId)
  // console.log("this.deteNotes", this.deleteNote);
  // this.productService.deleteProductNotes(this.specificNotesDetails).subscribe(data=>
  // console.log("data",data));
  // }

  /**
   * Name : Sandhya Misal
   * Functionality : Open popup
   * Code Desc :Open popup
   **/
  deleteNotesPopUp(productNotesId) {
    this.dialog.open(DeleteConfirmPopUpComponent, {
      data: {
        productId: productNotesId,
        notes: this.notes,
      },
    });
  }

}

@Component({
  selector: "popup-image",
  templateUrl: "popup-image.html",
})
export class PopUpContentComponent {
  constructor(
    public dialogRef: MatDialogRef<PopUpContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  closePopup(): void {
    this.dialogRef.close();
  }
  //Prod
  AZURE_BLOB_END_POINT =
    "https://prodcircorsmartsa.blob.core.windows.net/traceability/";
  QUESTION_MARK = "?";
  AZURE_BLOB_SAS_KEY =
    "sv=2019-12-12&ss=bfqt&srt=sco&sp=rwdlacupx&se=2025-12-31T22:31:05Z&st=2021-01-05T14:31:05Z&spr=https&sig=yGEgOLDyUbQLAYoOqPp%2FOsfdiWZFHho57UQ0QdLkxFA%3D";
  //test
  //AZURE_BLOB_END_POINT = 'https://circortesttraceabilitysa.blob.core.windows.net/traceability/'
  //QUESTION_MARK = '?'
  // AZURE_BLOB_SAS_KEY = 'sv=2019-12-12&ss=b&srt=sco&sp=rwdlacx&se=2021-05-31T13:02:14Z&st=2020-11-06T05:02:14Z&spr=https&sig=VpbVv3y0ct%2Bm9jmRz%2FKTPsZ52OpujKieBGhQSdg3rJg%3D'

  EncodedImageURL = encodeURI(this.data.image);
  azureBlobImagePath = this.data.image;

  ngOnInit(): void {
    this.azureBlobImagePath;
    console.log("this.azureBlobImagePath", this.azureBlobImagePath);
  }
}

@Component({
  selector: "delete-popup",
  templateUrl: "delete-popup.html",
})
export class DeleteConfirmPopUpComponent {
  deleteNote: any;

  deleteSpecificNote = {
    productNotesId: null,
    productSerialNo: null,
    productNotes: null,
    imageLoc: null,
    updatedBy: { operatorId: 0 },
    updatedOn: null,
    deletedBy: { operatorId: 0 },
  };

  addNotes = {
    productNotes: {
      productSerialNo: null,
      productNotes: null,
      imageLoc: null,
      updatedBy: { operatorId: 0 },
      updatedOn: null,
      isDeleted: null,
      deletedBy: { operatorId: 0 },
    },
    productNotesFile: [
      {
        filepath: null,
        fileType: null,
      },
    ],
  };

  todayISOString: string = new Date().toISOString();
  notesLength: number;
  notesFileCount: number;
  constructor(
    public dialogRef: MatDialogRef<DeleteConfirmPopUpComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private productService: ProductService,
    private snackBar: MatSnackBar,
    private dataSharingService: DataSharingService
  ) {}

  closePopup(): void {
    this.dialogRef.close();
  }
  /**
   * Name : Sandhya Misal
   * Functionality :Calling delete notes api
   * Code Desc :It will delete perticular notes
   **/
  deleteNotes() {
    this.deleteNote = this.data.notes.filter(
      (data) => data.productNotes.productNotesId == this.data.productId
    );
    console.log("this.deleteNotes", this.deleteNote);
    this.deleteSpecificNote.productNotesId =
      this.deleteNote[0].productNotes.productNotesId;
    this.deleteSpecificNote.productSerialNo =
      this.deleteNote[0].productNotes.productSerialNo;
    this.deleteSpecificNote.deletedBy =
      this.deleteNote[0].productNotes.updatedBy.operatorId;
    console.log("this.deleteSpecificNote", this.deleteSpecificNote);
    this.productService
      .deleteProductNotes(this.deleteSpecificNote)
      .subscribe((data) => {
        console.log("data", data);
        if (data.Message == "Success") {
          this.snackBar.open("The note has been deleted successfully", "", {
            duration: 3500,
          });
          this.dataSharingService.setNotes(true);
          // this.productService.fetchProductNotes(this.productId).subscribe(notes => {
          //   this.notes = notes;
          //   console.log("notes:",this.notes);
          //   this.notesLength = Object.keys(this.notes).length;
          //   console.log("notes length:",Object.keys(this.notes).length);
          //   // console.log("productNotesFile length:",Object.keys(this.notes.productNotesFile).length);
          //   for ( var notesFile of this.notes){
          //     this.notesFileCount = Object.keys(notesFile.productNotesFile).length;
          //   }

          // }),error => {
          //   console.log(error);
          // }
        } else {
          this.snackBar.open("error in deleting notes", "", {
            duration: 3500,
          });
        }
      }),
      (error) => {
        console.log(error);
      };
    this.dialogRef.close();
  }

  ngOnInit(): void {}
}
