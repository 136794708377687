<body>
  <div>
    <div class="row">
      <div class="imgBox">
        <h1 class="textOnImage f-m-s-b-36">EXCELLENCE IN FLOW</h1>
        <h1 class="textOnImage1 f-m-s-b-36">CONTROL</h1>
        
          <p class="blog f-m-l-18">
            CIRCOR manufactures flow and motion control products for the<br>
            world’s most severe-service and mission-critical applications—from<br>
            valves to instrumentation, actuation to pumps, motors to regulators.
          </p>
        
      </div>
      <div>
        <img class="imgLogo" src="../../assets/circor-Logo.png" height="46px" width="99px"/>
        <p class="textContent login-f-m-r">Welcome...!!Search for a product to view the details</p>
        <hr class="hrAlignment">
        <div>
          <form [formGroup]="unrestrictedUserForm">
            <div>
              <mat-form-field class="inputWidth">
                <mat-label class="f-m-r-14">Product ID</mat-label>
                <input formControlName="userName" matInput placeholder="Please enter Product Id / Serial No" #serialNo>
              </mat-form-field>
           </div>
           <div *ngIf="isMessage" class="alert alertDanger">
            Serial number "{{isSerialNo}}" could not be found, please try again.
          </div>
          <button [ngStyle]="{'margin-top':isMessage === true ? '-20px' : '29px' }" mat-button class="loginButton" type="submit" [disabled]="!unrestrictedUserForm.valid" (click)="submit(serialNo.value)">SEARCH</button>
        </form>
        </div>
      </div>
    </div>
  </div>
</body>
