import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {faqData} from '../mock-data/faqData'
import {faq} from '../models/faq';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import{ environment} from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(private http: HttpClient) { }

  // getFaq():Observable<faq[]>{
  //   return of(faqData);
  // }

  getFaq(status):Observable<any>{
    return this.http.post<any>(`${environment.apiUrl}productLine/getFaq`,status)
  }
}
