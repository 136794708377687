import { Component,ViewChild, OnInit } from '@angular/core';
import { ChangeEventArgs } from '@syncfusion/ej2-dropdowns';
import { GridComponent, FilterService, FilterType, RowSelectEventArgs, QueryCellInfoEventArgs  } from '@syncfusion/ej2-angular-grids';
import {MatSnackBar} from '@angular/material/snack-bar';
import { FetchUserDetailsService } from '../services/fetch-user-details.service';
import { UserDetails} from '../models/userDetails'
import { DataSharingService } from '../services/data-sharing.service';
import { Tooltip } from '@syncfusion/ej2-angular-popups';

@Component({
  selector: 'app-user-registration-details',
  templateUrl: './user-registration-details.component.html',
  styleUrls: ['./user-registration-details.component.css'],
  providers: [FilterService]
})
export class UserRegistrationDetailsComponent implements OnInit {
  public data: Object[];
    public ddldata: Object[];
    public pageSettings: Object;
    public filter: Object;
    public ddlfields: Object = { text: 'type', value: 'Id' };
    public formatoptions: Object;
    public toolbar: Object[];
    @ViewChild('grid')
    public grid: GridComponent;
    public item: Object = {}; 
    // selectRow:Object;
    public selectRow:Object[]=[];
    newProducts:any;
    userData:UserDetails[]=[];
    message:string;
    userRows:UserDetails[]=[];

  constructor(private snackBar: MatSnackBar, private userDetails:FetchUserDetailsService, private dataSharingService:DataSharingService) { }

  ngOnInit(): void {
    //  this.data = userData;
    this.userDetails.fetchUserDetails().subscribe(user=>{
      this.data=user;
      }, (err) => {
        console.log(err);
      })

      this.dataSharingService.fetchPendingArray.subscribe(pendingArray =>{
        console.log("CLicked on pending array",pendingArray);
        this.data=pendingArray;
      }
        );

        this.dataSharingService.gridValue.subscribe(data=>{
          console.log("booleanValue:",data);
          if(data==true)
          this.grid.refresh();
        })
           
        this.pageSettings = { pageCount: 5 };
        this.filter = { type: "CheckBox" };
        this.toolbar = ['Search'];
  }

  public onChange(e: ChangeEventArgs): void {
    this.grid.filterSettings.type = <FilterType>e.value;
    this.grid.clearFiltering();
}


rowSelected(args: RowSelectEventArgs) {
  const selectedrowindex: number[] = this.grid.getSelectedRowIndexes();  // Get the selected row indexes.
  const selectedrecords: object[] = this.grid.getSelectedRecords();
}

userApprove(data){

const selectedrecords: object = this.grid.getSelectedRecords();// Get the selected records.

console.log("selectedRows:",selectedrecords);

if(Object.keys(selectedrecords).length==1 && selectedrecords[0].operatorId==data.operatorId){

  selectedrecords[0].approvalStatus="Approved";
  console.log("Changed Status",selectedrecords[0].approvalStatus);

this.userData.push(selectedrecords[0]);
console.log("UserData",this.userData);

console.log("admin:",selectedrecords[0].adminId.firstName);
  this.userDetails.getStatusApproved(this.userData).subscribe(data=>
    {
console.log("Apidata",data);
 this.message = data.Message;
 if(this.message=='Success'){
  // this.grid.refresh();
  this.snackBar.open('Registration has been approved','', {
    duration: 3000,
    // panelClass: 'green-snackbar'
  })
  this.dataSharingService.setGrid(true);
 }
    }, (err) => {
      console.log(err);
    })
}
else{
    this.snackBar.open('Row should be selected','', {
      duration: 3000,
      // panelClass: 'green-snackbar'
    })
  }
}

userReject(data){
  const selectedrecords: object = this.grid.getSelectedRecords();

console.log("selectedRows:",selectedrecords);

if(Object.keys(selectedrecords).length==1 && selectedrecords[0].operatorId==data.operatorId){

  selectedrecords[0].approvalStatus="Rejected";
  console.log("Changed Status",selectedrecords[0].approvalStatus);

this.userData.push(selectedrecords[0]);
console.log("UserData",this.userData);

console.log("admin:",selectedrecords[0].adminId.firstName);
  this.userDetails.getStatusApproved(this.userData).subscribe(data=>
    {
console.log("Apidata",data);
 this.message = data.Message;
 if(this.message=='Success'){
   this.grid.refresh();
  this.snackBar.open('Registration has been Rejected','', {
    duration: 3000,
    // panelClass: 'green-snackbar'
  })
  this.dataSharingService.setGrid(true);
 }
    }, (err) => {
      console.log(err);
    })
}
else{
    this.snackBar.open('Row should be selected','', {
      duration: 3000,
      // panelClass: 'green-snackbar'
    })
  }
}

userRevoke(data){
  const selectedrecords: object = this.grid.getSelectedRecords();

console.log("selectedRows:",selectedrecords);

if(Object.keys(selectedrecords).length==1 && selectedrecords[0].operatorId==data.operatorId){

  selectedrecords[0].approvalStatus="Revoked";
  console.log("Changed Status",selectedrecords[0].approvalStatus);

this.userData.push(selectedrecords[0]);
console.log("UserData",this.userData);

console.log("admin:",selectedrecords[0].adminId.firstName);
  this.userDetails.getStatusApproved(this.userData).subscribe(data=>
    {
console.log("Apidata",data);
 this.message = data.Message;
 if(this.message=='Success'){
   this.grid.refresh();
  this.snackBar.open('Access has been revoked','', {
    duration: 3000,
    // panelClass: 'green-snackbar'
  })
  this.dataSharingService.setGrid(true);
 }
    }, (err) => {
      console.log(err);
    })
}
else{
    this.snackBar.open('Row should be selected','', {
      duration: 3000,
      // panelClass: 'green-snackbar'
    })
  }

console.log("selectedRows:",selectedrecords);
console.log(Object.keys(selectedrecords).length);
if(Object.keys(selectedrecords).length>=1 && selectedrecords[0].userID==data.userID){
}
else{
    this.snackBar.open('Row should be selected','', {
      duration: 3000,
      // panelClass: 'green-snackbar'
    })
  }
}

click(): void { 
  console.log(this.item); //Get the checkbox values here... 
}

}
